export const IgnoreErrors = [
    'loginFormData.userNameValue',
    '\'recipient\' is undefined',
    'Cannot read property \'move\' of undefined',
    'Cannot read property \'substr\' of undefined',
    'evaluating \'this.T.bind(this)\'',
    '\'undefined\' is not a function (evaluating \'g[a][l].bind(g[a])\')',
    'Unexpected token else',
    /^([a-z]{1})(\sis undefined)/g,
    'Cannot read property \'closingEls\' of undefined',
    'form is not defined',
    /\b(webpackJsonp)\b/g,
    'handleEvent',
    /JSON Parse error:.*/,
    'undefined is not a function',
    'Unexpected end of JSON input',
    '\'value\' is not defined',
    /\b(value)\b/g,
    'Attempted to assign to readonly property',
    /\b(google.*)\b/g,
    /Unexpected token.*/,
    /\b(substr.*)\b(undefined)/,
    'GetScreenshotBoundingBox',
    /a.replace is not a function.*/,
    /owlCarousel.*/,
    'e.getAttribute("type")',
    'vid_mate_check is not defined',
    'memory',
    "Cannot read property 'call' of undefined",
    'Blocked a frame with origin',
    'QuotaExceededError',
    'webpackJsonp',
    'Can\'t find variable: webpackJsonp',
    'Can\'t find variable: google_tag_manager',
    'native code',
    'Cludo is not defined',
    '"$" is not defined',
    'Unable to get property \'removeChild\' of undefined or null reference',
    'Cannot find function getClientRects',
    'window.sessionStorage',
    'Unclosed character class',
    'Can\'t find variable: formTouched',
    'Can\'t find variable: didEnterViewPort',
    'getClientRects',
    'htmlunit',
    'DataCloneError',
    'sessionStorage',
    /QuotaExceededError:.*/,
    /DataCloneError:.*/,
    /SecurityError:.*/,
    'Failed to execute \'postMessage\' on \'Window\'',
    'Not enough arguments to Window.postMessage',
    'net.sourceforge.htmlunit.corejs.javascript.Undefined',
    'Not enough arguments',
    'Argument not optional',
    'Non-Error exception',
    'Access is denied',
    'The operation is insecure',
    'evaluating \'F(a)\'',
    'this.f is undefined',
    'cssRules',
    'getBoundingClientRect',
    '\'getItem\' of null',
    '__isVuelidateAsyncVm',
    'WeixinJSBridge ',
    'this.filteredOptions[this.pointer].isLabel',
    '(0 , r.regex) is not a function',
    'undefined is not an object',
    'Non-Error exception',
    'getFirstImage',
    'Can\'t find variable: pktAnnotationHighlighter',
    'downloadTouchedElement is not defined',
    'this.newDepIds.clear is not a function', //SENTRY-411
    'Cannot read property \'config\' of undefined', // SENTRY-389
    'x5onSkinSwitch is not defined', //SENTRY-499
    'NS_ERROR_NOT_INITIALIZED', //SENTRY-514
    /Can\'t find variable: MyAppGet.*AtPoint/, // It's iOS SDK code
    'Can\'t find variable: GetImageTagSrcFromPoint',
    'getRandomIntInclusive is not defined', // SENTRY-645
    'The string did not match the expected pattern', // SENTRY-624
    'e.postMessage is not a function', //SENTRY-996
    // Ignore below 4 errors from SENTRY-831 which are all relate to iOS code
    'sdppromo',
    'og_head',
    '__init14',
    'There is no clipping info for given tab', //SENTRY-1110, Chrome Extension issue
    '__initialized',
    'null is not an object (evaluating \'article.element\')', // SENTRY-776, SENTRY-775
    'null is not an object (evaluating \'b.contentDocument.elementFromPoint\')',
    'getAbstract',
    'a[b].target.className.indexOf is not a function',
    'webkit',
    'e.action.startsWith is not a function',
    'ZhihuiOS',
    'webviewDidFinishLoad',
    'fidoCallback',
    '\'shareModalShown\' is undefined',
    'Can\'t find variable: modalShown',
    '\'server500ErrorShown\' is undefined',
    'Exception invoking open',
    'Unexpected end of input',
    'captureMessage(third-party',
    '__show__deepen',
    'The operation was canceled by the user',
    'Can\'t find variable: auto',
    'Not enough storage is available to complete this operation',
    'Unexpected identifier',
    'ss_post_message_to_iframe_injection ',
    'PrepareForElementCapture',
    '\'Symbol\' is undefined', // IE known issue but Resolved using pollyfils
    'Can\'t find variable: Symbol', // Reactivity issue a known issue over the framework. which doesn't cause any performance lag
    'ResizeObserver loop limit exceeded', // Chrome issue
    'ResizeObserver loop completed with undelivered notifications', //browser issue
    'null is not an object (evaluating \'document.head.querySelector("meta[name=\'supported-color-schemes\']").content\')', // IOS dark mode, will remove it once we support dark mode
    'Cannot read property \'style\' of undefined', // Linux issue over static pages on jquery library.
    'NotAllowedError: Window.print: Prompt was canceled by the user',
    'window.print is not a function',
    'can\'t redefine non-configurable property \"availWidth\"',
    'Non-Error promise rejection captured with keys', // spike in occurance but no real time error sentry issue
    '_AutofillCallbackHandler',  // happening due to facebook browser autofill issue, not related to our code
    'JSON syntax error', // SENTRY-1578
    'getAppDetails',
    'instantSearchSDKJSBridgeClearHighlight',
    'onfig is not defined',
    'Can\'t find variable: setIOSParameters',
    'overrideMethods is not defined',
    'Error: Attempt to postMessage on disconnected port',
    'Uncaught DOMException: XMLHttpRequest.responseText getter: responseText is only available if responseType is \'\' or \'text\'.',
    'This operation is not allowed',
    'An invalid or illegal string was specified',
    'new P.default',
    'Can\'t find variable: eundefined',
    'Cannot read property "$scopedSlots" from undefined',
    'Cannot read properties of null (reading \'currentTime\')',
    'TypeError: Load failed',
    'NetworkError: Load failed',
    'ifameElement.contentDocument.addEventListener',
    'showRequestCallBack is not defined',
    'Can\'t find variable: showRequestCallBack',
    'NetworkError when attempting to fetch resource.',
    '<unknown>',
    'The operation couldn\'t be completed. Software caused connection abort',
    'The network connection was lost.',
    'NS_BINDING_ABORTED',
    'Failed to execute \'measure\' on \'Performance\'',
    'cancelled',
    'NetworkError: Resource blocked by content blocker',
    '网络连接已中断。',
    'لا يمكن الاتصال بالخادم.',
    'La conexión de red se perdió.',
    '網絡連線中斷。',
    'The request timed out.',
    'Failed to fetch',
    'invalid flag after regular expression (https://apps.usw2.pure.cloud/genesys-bootstrap/genesys.min.js#11)',
    'Cannot set properties of null (setting \'lastSetTimerId\')',
    'null is not an object (evaluating \'this.lastSetTimerId=null\')',
    'Cannot read properties of null (reading \'__transferData\')',
    'Cannot read properties of undefined (reading \'domContentLoadedEventEnd\')',
    'Object captured as promise rejection with keys: body, bodyText, headers, ok, status',
    'Cannot assign to read only property \'modules\' of object \'#<Io>\'',
    'zr is null',
    'this is null',
    'bridgeInterface is not defined',
    'No identifiers allowed directly after numeric literal',
    '$tables_to_be_clipped$ is not defined',
    'Event `Event` (type=unhandledrejection) captured as promise rejection',
    'Cannot read properties of null (reading \'shadowRoot\')',
    'performance.getEntriesByType is not a function. (In \'performance.getEntriesByType("navigation")\', \'performance.getEntriesByType\' is undefined)',
    'Java object is gone',
    'vcmidOfContent is not defined',
    'Cannot read properties of undefined (reading \'L\')',
    'Cannot read properties of undefined (reading \'interceptXmlHttpRequest\')',
    'a is not an Object. (evaluating \'"textContent"in a\')', 
    'Uncaught NetworkError: Failed to execute \'importScripts\' on \'WorkerGlobalScope\'',
    'Invalid call to runtime.sendMessage(). Tab not found.',
];

export const IgonreUrls = [
    //ignoring facebook urls if they are not able to load due to restrictions in certain countries
    /graph\.facebook\.com/i,
    /connect\.facebook\.net\/en_US\/all\.js/i,

    //ignoring quora url if they are not able to load due to restrictions in certain countries
    /a\.quora\.com/i,

    //ignoring google tag manager urls if they are not able to load due to restrictions in certain countries or blocked in the browser
    /https?:\/\/((www)\.)?googletagmanager\.com/,
    /\/(gtm|ga|analytics)\.js/i,
    /https?:\/\/((translate)\.)?(googleapis|google)\.com/,
    /https?:\/\/((dev|www)\.)?visualwebsiteoptimizer\.com/,
    /https?:\/\/((apps|www)\.)?usw2.pure\.cloud/,
    /https?:\/\/uni.insubuy\.com/,
    // chrome errors
    /^chrome:\/\//i,
    /extensions\//i,
    /wchat\.freshchat\.com/i,
    'https://wchat.freshchat.com/js/widget.js',
    /assets\/js\/freshchat.*js/,
    /monitor\.clickcease\.com/,
    'https://monitor.clickcease.com/',
    'https://pagead2.googlesyndication.com/',
    // firefox errors

    /^resource:\/\//i
];

export const WhiteListUrls = [
     /https?:\/\/((www)\.)?insubuy\.com/,
     /https?:\/\/((\w+)\.)?brokersnexus\.com/
];

export const ShouldSendCallback = function (data) {
    const userAgentCopy = window.navigator.userAgent;
    return !/^(.*CloudFlare-AlwaysOnline.*|.*Opera Mini.*|.*UC Browser.*|.*Yandex Browser.*|.*Amazon Silk.*|.+MSIE 8\.0;.+)$/.test(userAgentCopy);
}