//Applies to step 3 on all applications.
import dayjs from 'dayjs';
import { 
  isChromeBrowser, 
  checkAgent,
  getStateDictionary,
  getStateCode,
  getAddrLength,
  validateEmail,
  atleastOneNumber,
  onlyAlphaNumeric
} from '../../../helper-functions/helper-functions';
import { LoginModalMixin } from './loginModal';
import IMGPlanIds from '../../../helper-functions/companies/img-planids';
import WTPlanIds from '../../../helper-functions/companies/worldtrips-planids';
import AZPlanIds from '../../../helper-functions/companies/azimuth-planids';
import TrawickPlanIds from '../../../helper-functions/companies/trawick-planids';
import PlanIds from '../../../helper-functions/all-planIds';
import { getItemSessionStorage, setItemSessionStorage, removeKeySessionStorage } from '../../../helper-functions/services';

const imgPlanIds = new IMGPlanIds();
const trawickPlanIds = new TrawickPlanIds();
const azPlanIds = new AZPlanIds();
const wtPlanIds = new WTPlanIds();

export default {
  data: function() {
    return {
      showOption: true,
      isChrome: isChromeBrowser(),
      emailInvalid: false,
      totalPrice: '',
      schengenWarning: null,
      isLoggedIn: ibJS?.affiliateValues?.myAccountLogin || false
    }
  },  
  mixins: [
    LoginModalMixin
  ],
  watch: {
    'form.confirmEmail': function (email) {
      if(!ibJS.UserLoggedInMyAcoount && !this.$v.form.confirmEmail.$invalid) {
        this.$http.post('/api/check-email/', {email})
          .then(function (response) {
            if (response.body && response.body.status === 'success') {
              if (response.body.data && response.body.data.isExist) {
                this.requestLoginModal();
                sessionStorage.setItem("isShowLogin", true);
              }
            }
          });
      }
    },
    'form.primaryEmail' (val) {
      this.validatedAffiliateEmail();
    },
    'form.beneficiary': {
      handler: function() {
        if(this.form.beneficiary) this.refactorBeneficiary();
      },
      deep: true
    },
    'form.contact': function() {
      if(this.form.contact) this.refactorContact();
    },
  },
  created: function() {
    this.formatBirthDates();
    const schengenWarning = getItemSessionStorage('schengenWarning') || false;
    if (schengenWarning && schengenWarning == 'true') this.schengenWarning = true;
  },
  computed: {
    preventFurtherSteps () {
      let doNotAllow = false;

      return doNotAllow;
    },
    planResultQuoteDuration () {
      return this.application?.planResult?.quotedDuration || null;
    },
    showSchengenWarning () {
      const allPlanIds = new PlanIds();
      if(allPlanIds.isAllInAppTripInsurance(this.application.planId)) {
        return false;
      }
      let show = false;
      // const allInternationalPlanIDs = [
      //   wtPlanIds.Atlas_Premium_International, 
      //   wtPlanIds.Atlas_Essential_International, 
      //   wtPlanIds.Atlas_International,
      //   wtPlanIds.ExchangeGuard_Choice,
      //   wtPlanIds.ExchangeGuard_Essential,
      //   wtPlanIds.ExchangeGuard_Group_Choice,
      //   wtPlanIds.ExchangeGuard_Group_Essential,
      //   imgPlanIds.Patriot_International_Lite, 
      //   imgPlanIds.Patriot_International_Platinum, 
      //   trawickPlanIds.Safe_Travels_Outbound_Parent, 
      //   trawickPlanIds.Safe_Travels_Outbound_All_Other_States,
      //   trawickPlanIds.Safe_Travels_Outbound_Penn_Missouri,
      //   trawickPlanIds.Safe_Travels_International,
      //   trawickPlanIds.Safe_Travels_International_Cost_Saver, 
      //   azPlanIds.Beacon_International,
      // ];
      let isSchengenCountry = false;
      if (ibJS.application.companyCode == 'IMG') {
        var countryList = this.form.destinationCountries.map(ctry=>ctry.country);
        var found = this.schengenCountries.some(r => countryList.indexOf(r) >= 0);
        isSchengenCountry = found ? true : false;
      } else {
        isSchengenCountry = this.schengenCountry;
      }
      if (isSchengenCountry && (this.application.deductible > 0 || this.application.policyMaximum < 50000)) {
        show = true;
      }
      return show;
    }
  },
  mounted: function() {
    checkAgent.call(this, [true]);  

    this.assignIndex();
    try {
      this.hideBirthdateValidations();
    } catch(e) {}

    // Continues after parsing phone logic created or mounted (depending on app)
    // APP-1983 trigger forces select2 update
    this.$nextTick(() => {
        if (this.form.primaryEmail) {
          this.validatedAffiliateEmail();
        }
    });

    const appStp3Email = getItemSessionStorage('appStp3Email');
    if (appStp3Email) {
        this.form.primaryEmail = appStp3Email;
        removeKeySessionStorage('appStp3Email');
    }
  },
  methods: {
    validatedAffiliateEmail () {
      const emailRegxValid = this.$v.form.primaryEmail.hasOwnProperty('email') ? this.$v.form.primaryEmail.email : false;
      if (emailRegxValid && ibJS.affiliateValues.affiliateType != 0) {
         this.$http.get(`/api/check-if-email-valid/?email=${this.form.primaryEmail}&affiliateId=${ibJS.affiliateValues.affiliateid}`)
          .then(res => {
            const responseBody = res.body;
            if (responseBody.status === 'success') {
              if (!responseBody.data.validEmail) {
                this.emailInvalid = true;
              } else {
                this.emailInvalid = false;
              }
            }
         }, error => {
           const responseBody = error.body;
           console.log(responseBody);
         })
      }
    },
    hideBirthdateValidations: function() {
      // used as an alternative to hideValidationsWhileTyping because hideValidationsWhileTyping
      // does not use event delegation.
      if(this.$refs.insuredInfo) {
        this.$refs.insuredInfo.addEventListener('keydown', e => {

          const validation = e.target.parentElement.parentElement.nextElementSibling;
          if(e.target.id.indexOf('birthDate') > -1 && validation) {

            validation.style.display = 'none';
            e.target.addEventListener('blur', () => validation.style.display = 'block');
          }
        }), true;
      }
    },
    assignIndex: function() {
      const toAssign = [document.getElementsByClassName('child-tvler'),
                document.getElementsByClassName('other-tvler')]

      toAssign.forEach( x => {
        if (x.length > 1) {
          Array.prototype.forEach.call(x, (y,i) => y.innerHTML = i+1 );
        }
      });
    },
    /**
     * A boolean value is set to showOption
     * showOption is used in step 3 for determining if passport / gov id is a required or an optional field.
     * showOption == true ? Optional field : Required field
     */
    govIdOptional() {
      if(ibJS.schengenCountries) {
        const schengenValues = ibJS.schengenCountries.map( x => x.value );
        const selectedCountries = this.form.destinationCountries;
        this.showOption = true;

        //when multiple countries can be selected. (Multiselect)
        if(Array.isArray(selectedCountries) && schengenValues && selectedCountries){
          this.showOption = !selectedCountries.some((dest) => schengenValues.indexOf(dest.country) > -1);
        }
        //when only one country can be selected.
        else if(schengenValues && selectedCountries) {
          this.showOption = schengenValues.indexOf(selectedCountries.country) === -1;
        }

      }
    },
    formatBirthDates: function() {
       // for traveler birthday display
      if(this.form.travelerInfos) {
        for(var i in this.form.travelerInfos){
          if(this.form.travelerInfos[i].birthDate){
            var formatDate = new Date(this.form.travelerInfos[i].birthDate);
            let newDate;
  
            if(window.globalLanguage.lang == 'zh'){
              newDate = dayjs(formatDate).format("YYYY-MM-DD")
            }
            else if(window.globalLanguage.lang == 'es'){
              newDate = dayjs(formatDate).format("DD/MM/YYYY")
            }
            else {
              newDate = dayjs(formatDate).format("MM/DD/YYYY")
            }
  
            this.form.travelerInfos[i].birthDate = newDate;
          }
        }
      }
    },
    showLoginModal: function() {
      this.requestLoginModal();
      sessionStorage.setItem("isShowLogin", true);
    },
    commonPreSaveData (preSave) {
      if(!this.$v.form.primaryEmail.$invalid && !this.$v.form.confirmEmail.$invalid) {
          preSave.primaryEmail = this.form.primaryEmail;
      } else if(!this.$v.form.primaryEmail.$invalid) {
          setItemSessionStorage('appStp3Email', this.form.primaryEmail);
      }

      return preSave;
    },
    refactorBeneficiary() {
      if(typeof(this.form.beneficiary) == 'string') {
        this.form.beneficiary = this.form.beneficiary?.trim()?.replace(/\t+/g, ' ');
      } else if(typeof(this.form.beneficiary) == 'object') {
        if(this.form.beneficiary.firstName) {
          this.form.beneficiary.firstName = this.form.beneficiary.firstName.trim()?.replace(/\t+/g, ' ');
        }
        if(this.form.beneficiary.lastName) {
          this.form.beneficiary.lastName = this.form.beneficiary.lastName.trim()?.replace(/\t+/g, ' ');
        }
        if(this.form.beneficiary.relationShip) {
          this.form.beneficiary.relationShip = this.form.beneficiary.relationShip.trim()?.replace(/\t+/g, ' ');
        }
      }
    },
    refactorContact() {
      this.form.contact = this.form.contact?.trim()?.replace(/\t+/g, ' ');
    },
    getAddrStateCode(homeAddress = false, mailingAddress = false) {
      global.eventHub.$on('states-obtained', (val) => {
          const stateDictionary = getStateDictionary(val);
            let windowWidth = window.innerWidth;
            if(mailingAddress && this.mailingAddrBook?.length) {
              for(let i = 0; i < this.mailingAddrBook.length; i++) {
                  this.mailingAddrBook[i].stateCode = getStateCode(this.mailingAddrBook[i].state, this.mailingAddrBook[i].country, stateDictionary);
              }
              this.mailingAddrs = getAddrLength(this.mailingAddrBook, windowWidth);
            }
            if(homeAddress && this.homeAddrBook?.length) {
              for(let i = 0; i < this.homeAddrBook.length; i++) {
                  this.homeAddrBook[i].stateCode = getStateCode(this.homeAddrBook[i].state, this.homeAddrBook[i].country, stateDictionary);
              }
              this.homeAddrs = getAddrLength(this.homeAddrBook, windowWidth);
            }
          
      });
    },
    makeContactAjaxCallSuper: function () {
        if( 
            (this.form.confirmEmail !== '' && validateEmail(this.form.confirmEmail) && this.form.primaryEmail == this.form.confirmEmail) ||
            (this.form.phoneNumber !== '' && atleastOneNumber(this.form.phoneNumber) && onlyAlphaNumeric(this.form.phoneNumber))
        ) { 
            var phone = this.form.phoneNumber;
            let url = `/api/application/save-email-phone/?id=${this.application.id}`;
            if (this.form.confirmEmail != '' && validateEmail(this.form.confirmEmail) && this.form.primaryEmail == this.form.confirmEmail) {
                url += `&email=${this.form.confirmEmail}`;
            }
            if (this.form.phoneNumber !== '' && atleastOneNumber(this.form.phoneNumber) && onlyAlphaNumeric(this.form.phoneNumber)) {
                url += `&phone=${encodeURIComponent(phone)}`;
            }
            this.$http.get(url);
            this.$emit('reset-session');
        }
    },
    isRepurchase: function() {
      if(window.ibJS.option === '0' || window.ibJS.option === '1' || window.ibJS.option === '2' || window.ibJS.option === '3' || window.ibJS.option === '4') return true;
      return false;
    }
  }
};