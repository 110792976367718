import { setItemLocalStorage, getItemLocalStorage, getItemSessionStorage, setItemSessionStorage  } from './services';

import dayjs from 'dayjs';
import 'dayjs/locale/zh';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
dayjs.extend(utc);
dayjs.extend(timezone);
/*
Formatting

*** create very helper a named function. ***
*** leave a two spaced gap in between every function. ***
*** add the function name to the export object at the bottom of the file. ***

 */

/* WorkerJS timer */
let timeoutId = 0;
let timeouts = {};

try {
  if(ibJS.sessionRemainingTime && ibJS.sessionWarningTime) {
    let worker = new Worker("/assets/js/timer-worker.js?v="+window.buildNumber);
    worker.addEventListener("message", function(evt) {
      let data = evt.data,
          id = data?.id,
          fn = timeouts[id]?.fn,
          args = timeouts[id]?.args,
          type = timeouts[id]?.type;

      if(fn) fn.apply(null, args);
      if (type == 'crtTimeout') delete timeouts[id];
    });

    window.crtTimeout = function(fn, delay) {
      let args = Array.prototype.slice.call(arguments, 2);
      timeoutId += 1;
      delay = delay || 0;
      let id = timeoutId;
      timeouts[id] = {fn: fn, args: args, type: 'crtTimeout'};
      worker.postMessage({command: "crtTimeout", id: id, timeout: delay});
      return id;
    };

    window.clrTimeout = function(id) {
      worker.postMessage({command: "clrTimeout", id: id});
      delete timeouts[id];
    };

    window.crtInterval = function(fn, delay) {
      let args = Array.prototype.slice.call(arguments, 2);
      timeoutId += 1;
      delay = delay || 0;
      let id = timeoutId;
      timeouts[id] = {fn: fn, args: args, type: 'crtInterval'};
      worker.postMessage({command: "crtInterval", id: id, timeout: delay});
      return id;
    };

    window.clrInterval = function(id) {
      worker.postMessage({command: "clrInterval", id: id});
      delete timeouts[id];
    };
  }
} catch(e) {
  //
}

/* End WorkerJS timer */

function allowOnlyNumbers (evt) {
  evt = evt ? evt : window.event;
  if(evt){
     var charCode = (evt.which) ? evt.which : evt.keyCode;
  if ((charCode > 31 && (charCode < 48 || charCode > 57 || charCode === 189 || charCode === 109)) && charCode !== 46) {
    evt.preventDefault();
    return true
   } else {
    return false;
    }
  }
}

/**
 * Gets difference between to dates in days
 * @param {String} date1 
 * @param {String} date2 
 */
function getDaysDifference(date1, date2) {
  // To set two dates to two variables 
  date1 = new Date(date1); 
  date2 = new Date(date2); 
    
  // To calculate the time difference of two dates 
  let Difference_In_Time = date2.getTime() - date1.getTime(); 
    
  // To calculate the no. of days between two dates 
  let Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);

  return Difference_In_Days;
}


function zipCodeValidation(value){
  var regex;
  if((this.form.mailingAddress && this.form.mailingAddress.country=='USA') || (this.form.homeCountryAddress && this.form.homeCountryAddress.country=='USA') || (this.form.billingAddress && this.form.billingAddress.country=='USA') || (this.form.country=='USA')){
    regex =  /^[\d]{5}([-\d]{5})*$/;
  }
  else{
    regex = /^[\w- ]+$/;
  }
  if(value){
    return regex.test(value);
  }
  else{
    return true;
  }
}

function convertTimeForCookie () {
  const dy = dayjs().add(1, 'y').format('MM/DD/YYYY hh:mm:ss a')
  return dy;
}


function zipCodeValidation4Residence(value) {
  let regex;
  if(this.form.residenceAddress && this.form.residenceAddress.country!=='USA'){
    regex = /^[\w- ]+$/;
  }
  else{
    regex =  /^[\d-]+$/;
  }
  if(value){
    return regex.test(value);
  }
  else{
    return true;
  }
}


function numbersSpacesAndLetters(val) {
    return /^[a-zA-Z0-9-]*$/.test(val);
}


function onlyNumbers(val) {
  if (val == '' || val == null) {
    return true;
  }

  return  /^\d+$/.test(val);
}


function onlyNumbersIgnoreSpace(val) {
  if (val == '' || val == null) {
    return true;
  }

  val = val.replace(/ /g, '');

  return  /^\d+$/.test(val);
}


function removeSpaces(val) {
  if (!val || typeof val !== 'string') return val;
  
  return val.split(" ").join("");
}


function numbersWithSpace(val) {
  if (val == '' || val == null) {
    return true;
  }

  return  /^[\d\s]+$/.test(val);
}


function onlyAlphaNumeric(val) {
  var re = /^[\x00-\x7F]+$/;
  // SENTRY-912
  if(val && typeof val === 'string'){
    return re.test(val.toLowerCase()) && /^([^'<>]+)$/.test(val.toLowerCase());
  }
  else{
    return true;
  }
}

//removed apostrophe
function onlyAlphaNumericWithApst(val) {
  var re = /^[\x00-\x7F]+$/;
  // SENTRY-912
  if(val && typeof val === 'string'){
    return re.test(val.toLowerCase()) && /^([^<>]+)$/.test(val.toLowerCase());
  }
  else{
    return true;
  }
}

function spouseAgeValidation(val) {
  if(val == '' || val == null){
    return true;
  }
  return  /^\d+$/.test(val);
}


function getParameterByName(name, url) {
  if (!url) url = window.location.href;
  name = name.replace(/[\[\]]/g, '\\$&');
  var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
    results = regex.exec(url);
  if (!results) return null;
  if (!results[2]) return '';
  return decodeURIComponent(results[2].replace(/\+/g, ' '));
}

function websiteUrlValidation (url) {
  if (!url || (url && !url.trim())) return true;
  return /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/gm.test(url);
}


function numbersWithDashSlash(val) {
  if (val == '' || val == null) {
    return true;
  }

  return  /^[a-zA-Z0-9-/]+$/.test(val);
}


function noAlphabets(val) {
  if (val == '' || val == null) {
    return true;
  }

  return  /^[^a-zA-Z]+$/.test(val);
}


function onlyAlpha(val) {
  if (val) {
    return  /^[a-zA-Z ]+$/.test(val);
  } else {
    return true;
  }
}
function alphaWithHyphens(val) {
  if (val) {
    return  /^[a-zA-Z\s-]+$/.test(val);
  } else {
    return true;
  }
}


function dateNotToday(val) {
  if (val) {
    let dateToCheck = createDateByLangStr(val);

    let today = new Date();
    let a = window.globalLanguage.lang !== 'zh' ? '/' : '-';
    let i =  window.globalLanguage.lang !== 'zh' ? 2 : 0;

    if (val.split(a)[i] != undefined) {
      return dateToCheck != 'Invalid Date' && val.split(a)[i].length > 3 ? today.setHours(0,0,0,0) !== dateToCheck.setHours(0,0,0,0) : true;
    }
  }
  return true;
}


function monthDate(val) {
  var flag = true;
  var lang = window.globalLanguage.lang;
  var monthLength = [ 31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31 ];

  var year;

  if(val) {
    val = val.trim();
    var dateArray = (lang == "zh" || lang == 'ja' || lang == 'ko') ? val.split("-") : val.split("/");
    var yearLength;
    if(Array.isArray(dateArray)) {
      if(lang == "zh" || lang == 'ja' || lang == 'ko') {
        yearLength = dateArray[0].length;
      }  else {
        yearLength = dateArray[2] ? dateArray[2].length : '';
      }
    }

    if(dateArray.length == 3 && yearLength === 4) {
      let month,day,monthStr,dayStr;

      if(lang == "en" || lang == 'it' || lang == 'ru') {
        month = parseInt(Number(dateArray[0]));
        day = parseInt(Number(dateArray[1]));
        year = Number(dateArray[2]);
        monthStr = dateArray[0];
        dayStr = dateArray[1];
      }
      if(lang == "es" || lang == "ar") {
        day = parseInt(Number(dateArray[0]));
        month = parseInt(Number(dateArray[1]));
        year = Number(dateArray[2]);
        monthStr = dateArray[1];
        dayStr = dateArray[0];
      }
      if(lang == "zh" || lang == 'ja' || lang == 'ko') {
        day = parseInt(Number(dateArray[2]));
        month = parseInt(Number(dateArray[1]));
        year = Number(dateArray[0]);
        monthStr = dateArray[1];
        dayStr = dateArray[2];
      }
      // Adjust for leap years
      if(year % 400 == 0 || (year % 100 != 0 && year % 4 == 0)) monthLength[1] = 29;
      if(month > 0 && month <= 12 && day > 0 && day <= monthLength[month - 1]) flag = true;
      else flag = false;

      // check for the month and day length to be less than 3

      if(dayStr && dayStr.length > 2 || monthStr && monthStr.length > 2) flag = false;

      if(flag && isNaN(year)) flag = false;

      if(year < 1000 || month == 0 || month > 12) return false;

    } else flag = false;

  }
  return flag;
}


function dateNotInPast(val) {
  if (val) {
    let dateToCheck = createDateByLangStr(val);
    let today = new Date();
    let a = window.globalLanguage.lang !== 'zh' ? '/' : '-';
    let i =  window.globalLanguage.lang !== 'zh' ? 2 : 0;

    if(val.split(a)[i] != undefined) {
      return dateToCheck != 'Invalid Date' && val.split(a)[i].length > 3 ? today.setHours(0,0,0,0) <= dateToCheck.setHours(0,0,0,0) : true;
    }
  }
  return true;
}

/**
 * 
 * @param {Date} val | Date in String MM/DD/YYYY
 * @param {Date} sysDate | Date in String MM/DD/YYYY
 * @returns Boolean
 */
function dateNotInFuture(val, sysDate, allowEmpty = false) {
  if(val){
  var dateToCheck = createDateByLangStr(val);
  var today = sysDate && typeof sysDate != 'object' ? new Date(sysDate) : new Date();

  return today.setHours(0,0,0,0) >= dateToCheck.setHours(0,0,0,0);
  }
  else{
    return allowEmpty;
  }
}


function isAfterField(field, disabled) {
  return function (val, parentVm) {
    if (disabled || !val) {
      return true;
    }
    else {
      let otherDate, thisDate;
      if (window.globalLanguage.lang == 'es') {
        if(parentVm[field]) {
          otherDate = parentVm[field].split('/');
          otherDate = new Date(otherDate[2], otherDate[1] - 1, otherDate[0]);
        }
      }
      else {
        otherDate = createDateByLangStr(parentVm[field]);
      }
      thisDate = createDateByLangStr(val);
      let a = window.globalLanguage.lang !== 'zh' ? '/' : '-';
      let i = window.globalLanguage.lang !== 'zh' ? 2 : 0;

      let datesAreValid = thisDate && otherDate && thisDate !== 'Invalid Date' && otherDate !== 'Invalid Date' && 
        thisDate.toDateString() !== null && thisDate.toDateString() !== 'Invalid Date' && otherDate.toDateString() !== null && otherDate.toDateString() !== 'Invalid Date';
      if(val.split(a)[i] && monthDate(parentVm[field])) {
        return val && datesAreValid && val.split(a)[i].length > 3 ? otherDate <= thisDate : true;
      }
      return true;
    }
  }
}


function isAfterDate(val1, val2) {
  let firstDate = dayjs(new Date(val1));
  let secondDate = dayjs(new Date(val2));

  return firstDate.isAfter(secondDate);
}


function isBeforeDepart(val) {
  var departDate = createDateByLangStr(this.form.departureDate);
  var thisDate = createDateByLangStr(val);

    return thisDate < departDate;
}


function isBeforeField(field,disabled) {
  return function (val, parentVm) {
    if(disabled){
      return true;
    }
    else{
      let otherDate,thisDate;
      if(window.globalLanguage.lang == 'es'){
        otherDate = parentVm[field].split('/');
        otherDate = new Date(otherDate[2], otherDate[1]-1 , otherDate[0]);
      }else{
        otherDate = createDateByLangStr(parentVm[field]);
      }
      thisDate = createDateByLangStr(val);
      let a = window.globalLanguage.lang !== 'zh' ? '/' : '-';
      let i =  window.globalLanguage.lang !== 'zh' ? 2 : 0;
      return thisDate != 'Invalid Date' && val.split(a)[i].length > 3 ? otherDate >= thisDate : true;
    }
  }
}


function numberNotNegative(val) {
  return val >= 0;
}


function mustBeChecked(val) {
  return val === true;
}


function numberGreaterThanZero(val){
  return val > 0;
}


function validDate(val){
  // Parse the date parts to integers
  var parts = val.split("/");
  var day = parseInt(parts[1], 10);
  var month = parseInt(parts[0], 10);
  var year = parseInt(parts[2], 10);

  // Check the ranges of month and year
  if(year < 1000 || month == 0 || month > 12)
    return false;

  var monthLength = [ 31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31 ];

  // Adjust for leap years
  if(year % 400 == 0 || (year % 100 != 0 && year % 4 == 0))
    monthLength[1] = 29;

  // Check the range of the day
  return day > 0 && day <= monthLength[month - 1];
}


function createDateByLangStr(string, language) {
  if (!string || typeof string !== 'string') return "Invalid Date";
  var lang = language || window.globalLanguage.lang;
  if (lang === 'en') {
    let date = string.split('/');
    date = new Date(date[2], date[0] - 1, date[1]);
    return date;
  }
  if (lang === 'es') {
    let date = string.split('/');
    date = new Date(date[2], date[1] - 1, date[0]);
    return date;
  }
  if (lang === 'zh') {
    let date = string.split('-');
    if(date instanceof Array && date.length < 3){
       date = string.split('/');
    }
    date = new Date(date[0], date[1] - 1, date[2]);
    return date;
  }
}

function isValidDate(d) {
  return d instanceof Date && !isNaN(d);
}

function addOrSubtractDate(dateInp, unit, amount, plusOrMinus, language=window.globalLanguage.lang) {
  let isoDate = new Date(convertDateToEN(dateInp,language));

  // SENTRY-808
  if (!isValidDate(isoDate)) {
    console.error('invalid date')
    return;
  }
  let isoDateStr = isoDate.toISOString();
  let changedDate;

  if(plusOrMinus === '+') {
    changedDate = dayjs(isoDateStr).add(amount,unit);
  } else if(plusOrMinusunit === '-') {
    changedDate = dayjs(isoDateStr).subtract(amount,unit);
  } else {
    console.log('plusOrMinus must be "+" or "-" for addOrSubtractDate.')
    return;
  }

  return getDateFromLanguage(changedDate, language);
}

function getLangIdByLang(lang=window.globalLanguage.lang) {
  if (lang === 'zh') {
    return 2;
  }
  if (lang === 'es') {
    return 3;
  }
  return 1;
}

/**
 * IF a given date string is in EN and it needs to be converted to zh/es or viceversa use this function.
 * 
 * @param {String} formattedDate | Date in any lang format en: MM/DD/YYYY es: DD/MM/YYYY zh: YYYY-MM-DD
 * @param {Number} langId | en: 1 es: 3 zh: 2
 * @param {String} language | in what language the given date has to be formated en/es/zh
 */
function convertFormattedServerTime(formattedDate, langId, language=window.globalLanguage.lang) {
  // takes in format: "07/06/2019"
  let dateParts;
  let year;
  let month;
  let day;

  if (langId === 1) { // english
    dateParts = formattedDate.split('/');
    month = dateParts[0];
    day = dateParts[1];
    year = dateParts[2];
  } else if(langId === 2) { // chinese
    dateParts = formattedDate.split('-');
    year = dateParts[0];
    month = dateParts[1];
    day = dateParts[2];
  } else if (langId === 3) { // spanish
    dateParts = formattedDate.split('/');
    day = dateParts[0];
    month = dateParts[1];
    year = dateParts[2];
  }

  switch(language) {
    case 'zh': return `${year}-${month}-${day}` // chinese
    case 'es': return `${day}/${month}/${year}` // spanish
    default: return `${month}/${day}/${year}` // english
  }
}

function convertDateString(dateInp, language=window.globalLanguage.lang) {
    // converts string from: transactionDate: "2019-06-10T09:38:00.000-05:00"
    let dateParts;
    let month;
    let day;
    let year;
    //  year/month/day
    if (!dateInp) return null;
    if (typeof dateInp === 'number') dateInp = new Date(dateInp).toISOString();
    if (typeof dateInp === "string") {
      if(dateInp.includes('T')) dateInp = dateInp.split('T')[0];
      if(dateInp.includes('-')) {
        dateParts = dateInp.split('-');
        month = dateParts[1];
        day = dateParts[2];
        year = dateParts[0];
      } else if(dateInp.includes('/')) {
        dateParts = dateInp.split('/');
        month = dateParts[0];
        day = dateParts[1];
        year = dateParts[2];
      } else {
        return '';
      }
      
      switch(language) {
        case 'zh': return `${year}-${month}-${day}` // chinese
        case 'es': return `${day}/${month}/${year}` // spanish
        default: return `${month}/${day}/${year}` // english
      }
    } else {
      return '';
    }
}

function convertDateStringByEnDate(dateInp, language=window.globalLanguage.lang) {
  let dateParts;
  let month;
  let day;
  let year;
  if (!dateInp) return null;
  if (typeof dateInp === "string") {
    dateParts = dateInp.split('/');
    month = dateParts[0];
    day = dateParts[1];
    year = dateParts[2];
  
    switch(language) {
      case 'zh': return `${year}-${month}-${day}` // chinese
      case 'es': return `${day}/${month}/${year}` // spanish
      default: return `${month}/${day}/${year}` // english
    }
  } else {
    return '';
  }
}

function getDateFromLanguage(dateInp, language=window.globalLanguage.lang, timezone = true) {
    let formatDate;
    let d = new Date(dateInp); // this wil be affected by TimeZone
    if(!timezone) { //Do not consider timezone. 
      var userTimezoneOffset = d.getTimezoneOffset() * 60000;
      d = new Date(d.getTime() + userTimezoneOffset);
    }
    let month = d .getMonth() + 1;
    let date  = d .getDate();

    if(month < 10) {
      month = "0"+month;
    }
    if(date < 10) {
      date = "0"+date;
    }

    if(language === 'zh') {
      formatDate = d .getFullYear() + '-' + month + '-' + date;
    } else if (language === 'es') {
      formatDate = date + '/' + month + '/' + d.getFullYear();
    } else if(language === 'en') {
      return month+'/'+date+'/'+d.getFullYear();
    }

    return formatDate;
}

function convertDateByLang(form, language=window.globalLanguage.lang){
  const translationsToCheck = ['intlInsuranceExpDate','startDate','endDate',
                 'departureDate','returnDate','arrivalDateUS', 'initialTripDepositDate'];

  for(let x of translationsToCheck) {
    if(form[x]) {
      form[x] = getDateFromLanguage(form[x], language);
    }
  }

}


function convertDateToEN(date, language=window.globalLanguage.lang) {
  if (!date) return null;
    let formattedDate = date;

    if(language === 'zh') {
        const dateParts = date.split('-');
        formattedDate = `${dateParts[1]}/${dateParts[2]}/${dateParts[0]}`;
    } else if (language === 'es') {
        const dateParts = date.split('/');
        formattedDate = `${dateParts[1]}/${dateParts[0]}/${dateParts[2]}`;
    }

    return formattedDate;
}


function createDateByLangApplications(form, language){
  if(language == 'zh'){
    if(form.intlInsuranceExpDate && form.intlInsuranceExpDate !== '') {
      var formattedDepartureDate =  form.intlInsuranceExpDate.split('-');
      formattedDepartureDate = formattedDepartureDate[1] + '/' + formattedDepartureDate[2] + '/' + formattedDepartureDate[0];
      form.intlInsuranceExpDate = formattedDepartureDate;
    }
    if(form.departureDate && form.departureDate !=''){
      var formattedDepartureDate =  form.departureDate.split('-');
      formattedDepartureDate = formattedDepartureDate[1] + '/' + formattedDepartureDate[2] + '/' + formattedDepartureDate[0];
      form.departureDate = formattedDepartureDate;
    }
    if(form.returnDate && form.returnDate != ''){
      var formattedDepartureDate =  form.returnDate.split('-');
      formattedDepartureDate = formattedDepartureDate[1] + '/' + formattedDepartureDate[2] + '/' + formattedDepartureDate[0];
      form.returnDate = formattedDepartureDate;
    }
    if(form.arrivalDateUS && form.arrivalDateUS != ''){
      var formattedDepartureDate =  form.arrivalDateUS.split('-');
      formattedDepartureDate = formattedDepartureDate[1] + '/' + formattedDepartureDate[2] + '/' + formattedDepartureDate[0];
      form.arrivalDateUS = formattedDepartureDate;

    }
    for(var i in form.travelerInfos){
      if(form.travelerInfos[i].birthDate) {
        var formatDate = form.travelerInfos[i].birthDate.split('-');
        formatDate = formatDate[1] + '/' + formatDate[2] + '/' + formatDate[0];
        form.travelerInfos[i].birthDate = formatDate;
      }
    }
  }
  if(language == 'es'){
    if(form.intlInsuranceExpDate && form.intlInsuranceExpDate !== '') {
      var formattedDepartureDate = form.intlInsuranceExpDate.split('/');
      formattedDepartureDate = formattedDepartureDate[1] + '/' + formattedDepartureDate[0]  + '/' + formattedDepartureDate[2];
      form.intlInsuranceExpDate = formattedDepartureDate;
    }
    if(form.departureDate && form.departureDate !=''){
      var formattedDepartureDate = form.departureDate.split('/');
      formattedDepartureDate = formattedDepartureDate[1] + '/' + formattedDepartureDate[0]  + '/' + formattedDepartureDate[2];
      form.departureDate = formattedDepartureDate;
    }
    if(form.returnDate && form.returnDate != ''){
      var formattedDepartureDate = form.returnDate.split('/');
      formattedDepartureDate = formattedDepartureDate[1] + '/' + formattedDepartureDate[0]  + '/' + formattedDepartureDate[2];
      form.returnDate = formattedDepartureDate;
    }
    if(form.arrivalDateUS && form.arrivalDateUS != ''){
      var formattedDepartureDate = form.arrivalDateUS.split('/');
      formattedDepartureDate = formattedDepartureDate[1] + '/' + formattedDepartureDate[0]  + '/' + formattedDepartureDate[2];
      form.arrivalDateUS = formattedDepartureDate;
    }
    if(form.travelerInfos){
      for(var i in form.travelerInfos){
        if(form.travelerInfos[i].birthDate){
          var formatDate = form.travelerInfos[i].birthDate.split('/');
          formatDate = formatDate[1] + '/' + formatDate[0]  + '/' + formatDate[2];
          form.travelerInfos[i].birthDate = formatDate;
        }
      }
    }
    if(form.startDate && form.startDate !== ''){
      var formattedDepartureDate = form.startDate.split('/');
      formattedDepartureDate = formattedDepartureDate[1] + '/' + formattedDepartureDate[0]  + '/' + formattedDepartureDate[2];
      form.startDate = formattedDepartureDate;
    }
    if(form.endDate && form.endDate !== ''){
      var formattedDepartureDate = form.endDate.split('/');
      formattedDepartureDate = formattedDepartureDate[1] + '/' + formattedDepartureDate[0]  + '/' + formattedDepartureDate[2];
      form.endDate = formattedDepartureDate;
    }
  }

  return form;
}


function convertDatei18n_es(date) {
  var splitDates = date.split(/\//);
  return [splitDates[1],splitDates[0],splitDates[2]].join('/');
}


function resetThumbView() {
  var viewportWidth = $(window).outerWidth();
  var thumbLayout;
  try{
    thumbLayout = getItemSessionStorage('thumbLayout');
  } catch(e) {
    console.log("Session Storage is not accesible");
  }

  if(thumbLayout==true && viewportWidth < 993) {
    $('section').removeClass('is-Thumb-layout');
  }
  else if(thumbLayout==true && viewportWidth > 993) {
    $('section').addClass('is-Thumb-layout');
  }
}


function isMinAge(val,startDate){
  if(val){
    var currentDate = new Date();
    var birthDate = new Date(val);
    currentDate.setDate(currentDate.getDate() - 14);
    currentDate.setHours(0);
    currentDate.setMinutes(0);
    currentDate.setSeconds(0);
    // this will give the difference between the birthdate and the start date in milliseconds
    var result = Date.parse(startDate) - Date.parse(birthDate)
    // this will check if the time difference between the two values is greater than 14 days
    var cd = 24 * 60 * 60 * 1000,
      ch = 60 * 60 * 1000,
      d = Math.floor(result / cd),
      h = Math.floor( (result - d * cd) / ch),
      m = Math.round( (result - d * cd - h * ch) / 60000),
      pad = function(n){ return n < 10 ? '0' + n : n; };
    if( m === 60 ){
      h++;
      m = 0;
    }
    if( h === 24 ){
      d++;
      h = 0;
    }
    if(d>= 14){
      return true;
    }else{
      return false;
    }
  }
}


function isDateGreaterThanToday(expMonth,expYear) {
  var d1 = new Date(expYear, (+expMonth - 1));
  var today = new Date();
  if (d1.getMonth() < today.getMonth() && d1.getFullYear() == today.getFullYear()) {
    return false;
  } else {
    return true;
  }
}


function validateEmail(email) {
  var regexEmail = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  return regexEmail.test(String(email).toLowerCase());
}


function validatePhoneNumber(telNum) {
  var isValid = false;
  var isPhoneNumber = /[a-zA-Z[\]{}*+?.,!~@`%&//\\^$|#]/g.test(telNum);

  if(telNum && telNum.length) {
    if (isPhoneNumber) {
      return false;
    }
    var onlyNumber = telNum.replace(/[^0-9]/gi, "");
    if (onlyNumber && (onlyNumber.length < 4 || onlyNumber.length >12)) {
      return false;
    }
    isValid =  true;
  } else {
    isValid =  true;
  }

  return isValid;
}


function dateFormat(val) {
  if(val){
  return  /^\d{2}\/\d{2}\/\d{4}$/.test(val);
  }
  else{
    return false;
  }
}


function isGenderAdded(field1,field2){
  return function (val, parentVm) {
    var bDay =  parentVm[field1];
    var gender = parentVm[field2];
    if(gender != '' && gender != null)
    {
      if((val == '' || val == null) && (bDay == '' || bDay == null))
      {
        return false;
      }
    }
    return true;
  }

}


function isAgeAdded(field1,field2){
  return function (val, parentVm) {
    var bDay =  parentVm[field1];
    var age = parentVm[field2];
    if((age != '' && age != null) || (bDay != '' && bDay != null) )
    {
      if(val == '' || val == null)
      {
        return false;
      }
    }
    return true;
  }
}


function isGenderSame(field1){
  return function (val, parentVm) {
    var gender =  parentVm[field1];
    if(val != null && val != '' && gender != null && gender != null)
    {
      if(val == gender)
      {
        return false;
      }
    }
    return true;
  }
}


function isCheckBoxSelected(field1){
  return function (val, parentVm) {
    var checkBoxValue =  parentVm[field1];
    if((val == null || val == '') && checkBoxValue == true)
    {
      return false;
    }
    return true;
  }
}


function totalDaysBetweenDates(begin,finish){
    var start = createDateByLangStr(begin);
    var end = createDateByLangStr(finish);
    if(typeof(end.getDate) != 'function' || typeof(start.getDate) != 'function') return null;
    const startFormated = dayjs(`${end.getDate()}/${end.getMonth()}/${end.getFullYear()}`);
    const endFormated = dayjs(`${start.getDate()}/${start.getMonth()}/${start.getFullYear()}`);
    return  startFormated.diff(endFormated,'day')+1;
}


function getAgeFromBirthday(x){
  var bd = createDateByLangStr(x);
  if(!bd || !bd.getFullYear || typeof(bd.getFullYear) != 'function') return 0;
  var d = new Date();
  var years = d.getFullYear() - bd.getFullYear();

  return bd.getMonth() - d.getMonth() < 0 ?
       years : bd.getMonth() - d.getMonth() == 0 && bd.getDate() - d.getDate() <= 0 ? years : years - 1;
}

function maxAge(maximum) {
  if (typeof maximum !== 'number') maximum = parseInt(maximum);

  return function (val) {
    return getAgeFromBirthday(val) <= maximum
  }
}

function maxNum(maximum) {
  if (typeof maximum !== 'number') maximum = parseInt(maximum);

  return function (val) {
    return val <= maximum
  }
}

function minNum(minimum) {
  if (typeof minimum!== 'number') minimum = parseInt(minimum);

  return function (val) {
    return val >= minimum
  }
}

function checkCanadaPostalCode(code) {
  var regex = /^(?!.*[DFIOQU])[A-VXY][0-9][A-Z] ?[0-9][A-Z][0-9]$/;
  var pass =  regex.test(code);
  if(code != null && code != '' && pass == true) {
    return true;
  }
  else{
    return false;
  }
}


function checkUSzipCodeFormat(code) {
  var regex = /^\d{5}(?:[-]\d{4})?$/;
  var pass = regex.test(code);
  if(code != null && code != ''  && pass == true){
    return true;
  }
  else{
    return false;
  }
}


function atleastOneNumber(number) {
  var regex =  /.*[0-9].*/;
  if (!number) return true;
  return regex.test(number);
}

function atleastTenNumbers (number) {
  if (!number) return true;
  var regexTxt =  number.replace(/[^0-9]/g, '');
  return regexTxt && regexTxt.length >=10 ? true : false;
}


function findReferralKey(item) {
  var key;
  switch(item) {
    case "baidu":
      key = 0;
      break;
    case "bing":
      key = 1;
      break;
    case "duckduckgo":
      key = 2;
      break;
    case "facebook":
      key = 3;
      break;
    case "friend":
      key = 4;
      break;
    case "google":
      key = 5;
      break;
    case "immihelp":
      key = 6;
      break;
    case "instagram":
      key = 7;
      break;
    case "magazine":
      key = 8;
      break;
    case "quora":
      key = 9;
      break;
    case "radio":
      key = 10;
      break;
    case "repeat":
      key = 11;
      break;
    case "school":
      key = 12;
      break;
    case "search":
      key = 13;
      break;
    case "tv":
      key = 14;
      break;
    case "x":
      key = 15;
      break;
    case "wechat":
      key = 16;
      break;
    case "whatsapp":
      key = 17;
      break;
    case "youtube":
      key = 18;
      break;
    case "tiktok":
      key = 19;
      break;
    case "linkedin":
      key = 20;
      break;
    case "pns":
      key = 22;
      break;
  }
  return key;
}


function getDurationForApplicationsWithOptions(plan,options) {
  if(plan && typeof plan === 'object'){
    if(options !== undefined){
      if(options.hasOwnProperty("studentSecureMonthly")){
        if(options.studentSecureMonthly == '2' || (plan.scMonths && +plan.scMonths === 1)){
          // if student secure then special scenario for monthly payments
          if(plan.scMonths > 1){
            plan.quotedDuration = plan.scMonths + " " + window.qResultsLang.months;
          }
          else{
            plan.quotedDuration = plan.scMonths + " " + window.qResultsLang.month;
          }
        }
        else{
          getDurationForApplications(plan)
        }
      }
    }
  }
}


function getDurationForApplications(plan) {
  //added type checking for SENTRY-467
  if(plan && typeof plan === 'object'){
    //APP-991
    plan.quotedDuration="";

    if (plan.calcMonths > 0) {
      if (plan.calcMonths == 1) {
        plan.quotedDuration = plan.calcMonths + " " + window.qResultsLang.month;
      } else if (plan.calcMonths > 1) {
        plan.quotedDuration = plan.calcMonths + " " + window.qResultsLang.months;
      }
    }

    if (plan.calcFortnight) {
      plan.quotedDuration = " 15 " + window.qResultsLang.days + " ";
    }

    if (plan.calcDays > 0) {
      if(plan.quotedDuration.length > 0) {
        plan.quotedDuration += ", ";
      }
      if (plan.calcDays == 1) {
        plan.quotedDuration += plan.calcDays + " " + window.qResultsLang.day;
      } else if (plan.calcDays > 1) {
        plan.quotedDuration += plan.calcDays + " " + window.qResultsLang.days;
      }
    }

    if ((plan.calcMonths == 0) && (!plan.calcFortnight) && (plan.calcDays == 0)) {
      plan.quotedDuration = "N/A";
    }

    return plan.quotedDuration;
  }
}

let regxIMGAlphaNumeric =  new RegExp ("^([\\w ()\\-'#/.,;@&:%ÀÂÃÄÅÆÇÈÉÊËÌÍÎÏÐÑÒÓÔÕÖØÙÚÛÜÝÞßàáâãäåæçèéêëìíîïðñòóôõöøùúûüýþÿ]+)$");

function alphaNumericIMG(x){
  if(x && !x.split("").every( y => y === 'w')) {
    return regxIMGAlphaNumeric.test(x);
  }
  return true;
}

function alphaNumericIMGPhone(x){
  if(x && !x.split("").every( y => y === 'w')) {
    x = x.replace(/^[+1{1,}]/g,"");
    return regxIMGAlphaNumeric.test(x);
  }
  return true;
}


function addressStringIMG(x){
  if(x && x!== ''){
    let r =  new RegExp ("^([\\w ()\\-'#/.,;@:ÀÂÃÄÅÆÇÈÉÊËÌÍÎÏÐÑÒÓÔÕÖØÙÚÛÜÝÞßàáâãäåæçèéêëìíîïðñòóôõöøùúûüýþÿ]+)$");
    return r.test(x);
  }
   return true;
}


function nameRegexValidationIMG(x){
  let r =  new RegExp ("^[a-zA-Z ()\\-\\ '/.,ÀÂÃÄÅÆÇÈÉÊËÌÍÎÏÐÑÒÓÔÕÖØÙÚÛÜÝÞßàáâãäåæçèéêëìíîïðñòóôõöøùúûüýþÿ]+$");
  return r.test(x);
}


function phoneNumberIMG(x){
  //APP-993
  let r =  new RegExp ("^([0-9|\()\.\ -]+)$");
  return r.test(x);
}


function firstAndLastNameCheck(x){
  let reg = /([a-zA-Z]{1}) ([a-zA-Z]{1})?.+/;
  return reg.test(x);
}


function IMGRoutingNumber(x) {
  let r = new RegExp("^((0[0-9])|(1[0-2])|(2[1-9])|(3[0-2])|(6[1-9])|(7[0-2])|80)([0-9]{7})$");
  return r.test(x);
}


function isInPlanList(planArray, planId) {
  return planArray.indexOf(planId) > -1;
}


function isAfterFieldIMGCondition(field, disabled) {
  return function (val) {
    if (disabled) {
      return true;
    }
    else {
      let otherDate, thisDate;
      otherDate = field.split('T')[0].split('-');
      otherDate = new Date(otherDate[0], otherDate[1]-1, otherDate[2]);
      thisDate = createDateByLangStr(val);
      let a = window.globalLanguage.lang !== 'zh' ? '/' : '-';
      let i = window.globalLanguage.lang !== 'zh' ? 2 : 0;
      return val && thisDate != 'Invalid Date' && otherDate != 'Invalid Date' && val.split(a)[i].length > 3 ? otherDate <= thisDate : true;
    }
  }
}


function capitalizeString(str) {
  if(typeof str === 'string') {
    return str[0].toUpperCase() + str.slice(1,str.length);
  }
  return str;
}


function lessThan130Years(x) {
  //checks that the year is greater than 130 years from today's date.
  let convertedDate = createDateByLangStr(x);
  let today = new Date();
  let then = new Date(convertedDate);

  if(today.getFullYear() - then.getFullYear() < 130) {
    return true;
  }
  // else if(today.getFullYear() - then.getFullYear() === 130){
  //     if(today.getMonth() > then.getMonth()) {
  //         return true;
  //     } else if(today.getMonth() === then.getMonth()) {
  //         if(today.getDate() <= then.getDate()) {
  //             return true;
  //         }
  //     }
  // }
  return false;
}


function alphaNumericInsubuyIMG(x){
  if(x){
    if(alphaNumericIMG(x) === true) return onlyAlphaNumeric(x);
    else return false;
  }
  return true;
}

function splitPhoneNumber(phoneNumber) {
  if (phoneNumber && phoneNumber.indexOf('-') > 0 && phoneNumber.indexOf('+') === 0) {
    let index = phoneNumber.indexOf('-');
    phoneNumber = phoneNumber.substring(index + 1);
  }
  return phoneNumber;
}

function alphaNumericInsubuyIMGPhone(phoneNumber) {
  phoneNumber = splitPhoneNumber(phoneNumber);
  return alphaNumericInsubuyIMG(phoneNumber);
}


function alphaNumericInsubuyIMGState(x) {
  //let country = this.form.mailingAddress ? this.form.mailingAddress.country : this.form.billingAddress.country;
  if(x){
    return alphaNumericInsubuyIMG(x);
  }
  return true;
}

function nameRegexInsubuyIMG(x){
  if(x){
    if(nameRegexValidationIMG(x) === true) return onlyAlphaNumeric(x);
    else return false;
  }
  return true;
}


function addressStringInsubuyIMG(x){
  if(x){
    if(addressStringIMG(x) === true) return onlyAlphaNumeric(x);
    else return false;
  }
  return true;
}


//requireChildren is similar to requireChildrenAndOthers. This validates
//inputs when user is coming from Quote Results.
//requireChildrenAndOthers still applies when this is added.
function requireChildren(x,a){
  //SENTRY-684
  if(this.getTravelerArray) {
    let tvl = a.travelerType;
    let trvlers = this.getTravelerArray();
    let primaryAndSpouseLength = this.getTravelerArray().length;
    let tvlsClone = this.form.travelerInfos.slice(0, this.form.travelerInfos.length);

    if(tvlsClone[1] && tvlsClone[1].travelerType ==='spouse') {
      primaryAndSpouseLength--;
    }
    else if(tvlsClone[1] && trvlers[1].age < 18) {
      primaryAndSpouseLength--;
    }

    this.spliceTravelers(tvlsClone);

    if(tvl === 'child') {
      let val = x !== null && x !== "";
      if(val === false) {
        this.showChildErrorText = true;
        this.stopRequireChildrenAndOthers = true;
      }
      return val;
    }
    else if(tvlsClone.length < primaryAndSpouseLength) {
      this.showChildErrorText = true;
      this.stopRequireChildrenAndOthers = true;
      return false;
    }
  }
  return true;
}


//requireChildren is similar to requireChildrenAndOthers. requireChildren validates
//inputs when user is coming from Quote Results.
//requireChildrenAndOthers always validates when more travelers are added than the default (usually 3).
function requireChildrenAndOthers(x,a){
  if(this.getTravelerArray) {
    let travelers = this.getTravelerArray();
    if((a.travelerType === 'child' || a.travelerType === 'other' ||
      (travelers.length > 2 && travelers[2].travelerType === ''))
      && this.stopRequireChildrenAndOthers === false) {
      let moreThanOneOfType = this.checkIfMoreThanOneOfType(a.travelerType);
      let childCounter = 0, otherCounter = 0; //traveler Type Count
      let childErrorCount = 0, otherErrorCount = 0; //Error Count

      if(moreThanOneOfType) {
        let arr = travelers;

        //get total number of children and others;
        //if children/other has more than one in its counter and more than 0
        //unfilled fields, make respective show error variable true. Otherwise return true.
        for(let i = 0; i < travelers.length; i++) {
          let type = travelers[i].travelerType;
          let age = travelers[i].age
          let initialAge = 0;
          try{
            initialAge = ibJS.application.travelerInfos[i].age;
          } catch(e){}

            if(type === 'child') {
              childCounter++;
              if(isNaN(parseInt(age))) {
                childErrorCount++;
              }
            } else if(type === 'other' || (i > 1 && type === "" && (i !== 1 || initialAge === -1))) {
              otherCounter++;
              if(isNaN(parseInt(age))) {
                otherErrorCount++;
              }
            }
          }
        }

        if(a.travelerType === 'child') {
          this.showChildErrorText = childCounter > 1 && childErrorCount > 0;
        }
        else if(a.travelerType === 'other' || a.travelerType === '') {
          this.showOtherErrorText = otherCounter > 1 && otherErrorCount > 0;
        }

        if(this.showChildErrorText || this.showOtherErrorText) {
          return false;
        }
      }
    }
  return true;
}


// Function that validates only numbers - and space
function phoneNumberiTI(x) {
    let phoneRegx = /^(?=.*[0-9])[- +0-9]+$/g;
    return phoneRegx.test(x);
}

function validatePhoneNumberApp(x)  {
  let phoneRegx = /^(\+[0-9]{1,5}-[0-9]{4,15})$/g;
  return phoneRegx.test(x);
}

function setPostalMinLength(x) {
    return x && x.length < 5 ? false : true;
}

function zipCodeValidationChk(value){
    var regex =  /^[\d-]+$/;

    if(value){
        return regex.test(value);
    }
    else{
        return true;
    }
}

function accountingSettings(hasDecimals) {
    return {
      currency: {
          symbol : "$",   // default currency symbol is '$'
          format: "%s%v", // controls output: %s = symbol, %v = value/number (can be object: see below)
          decimal : ".",  // decimal point separator
          thousand: ",",  // thousands separator
          precision : hasDecimals ? 2 : 0   // decimal places 0 for none
      },
      number: {
          precision : 0,  // default precision on numbers is 0
          thousand: ",",
          decimal : "."
      }
    }
  }


function setTimeOutFun(isMyAccount) {
  if(ibJS.sessionRemainingTime && ibJS.sessionWarningTime) {

    if (isMyAccount) {
      setItemLocalStorage('lastMyAccountActivity', dayjs().toISOString());
    }

    this.dates = dayjs(ibJS.sessionRemainingTime);

    if(typeof(crtTimeout) == 'undefined') {
      return;
    }
    this.sessionExtensionTimer = crtTimeout(() => {
      // Show timer
      this.showSessionTimer = true;
      let $cookiePopin = $(".cookie-popin");
      const isCookiePopInHidden = $cookiePopin.hasClass("hide");
      if (!isCookiePopInHidden) {
        let cookiePopinHeight = $cookiePopin.height();
        crtTimeout(() => {
          $(".timeout-popin").css("bottom", (cookiePopinHeight + 1) + "px")
        }, 100);
      }
      // Start counting down
      this.timerInterval = crtInterval(() => {
        if (isMyAccount) {
          let lastTime = getItemLocalStorage('lastMyAccountActivity');
          let sessionWarningSec = ibJS.sessionWarningTime / 1000;
          if (lastTime && dayjs().diff(dayjs(lastTime), 'second') < sessionWarningSec) {
            this.showSessionTimer = false;
            this.dates = dayjs(ibJS.sessionRemainingTime);
          } else {
            this.showSessionTimer = true;
          }
        }

        if (this.showSessionTimer) {
          if (this.dates.format("mm") == "00" && this.dates.format("ss") == "00") {
            clearInterval(this.timerInterval);
            clrTimeout(this.sessionExtensionTimer);
            if (isMyAccount) {
              myAccountLogout.call(this);
            } else {
              window.location.href = ibJS.purchaseLink || '/';
            }
          } else {
            this.dates = dayjs(this.dates.subtract(1, 'second'));
          }
        }
      }, 1000);
    }, ibJS.sessionWarningTime);
  }
}

function resetExpirationTimer(isMyAccount) {
  if (isMyAccount) {
    setItemLocalStorage('lastMyAccountActivity', dayjs().toISOString());
  }

  let self = this;
  let url = '/api/session-continue/';
  self.$http.get(url);
  if (self.sessionExtensionTimer && typeof(clrTimeout) != 'undefined') {
    clrTimeout(self.sessionExtensionTimer);
    clrInterval(self.timerInterval);
    self.sessionExtensionTimer = null;
  }
  self.showSessionTimer = false;
  self.setTimeOutFun();
}

function numbersWithComma(val) {
  if (val == '' || val == null) {
    return true;
  }

  return /^(\d+\d{0,3}(,\d+)*)?$/.test(val);
}

function copyToClipboard(textToCopy) {
  $("body")
      .append($('<textarea name="fname" class="textToCopyInput"/>' )
      .val(textToCopy))
      .find(".textToCopyInput")
      .select();
    try {
      document.execCommand('copy');
    } catch (e) {}
   $(".textToCopyInput").remove();
}

function getStateDictionary(allStates) {
    let res = {};
    if (allStates && allStates.length) {
      for (let i = 0; i < allStates.length; i++) {
          const state = allStates[i];
          if (state.country && state.state && state.code) {
            if(!res[state.country]) res[state.country] = {};
            res[state.country][state.code] = state.state;
          }
      }
    }
    return res;
}

function getStateCode(state, country, stateDictionary) {
  if(country == 'USA') return state;
  if(stateDictionary && stateDictionary[country] && stateDictionary[country][state]) {
    return stateDictionary[country][state];
  }
  return state;
}

function getAddressStr(item, type, isFullCountryName) {
  let result = '';

  if(item) {
    let mailingName = item.mailingName || item.contact;
  
    if(type === 'mailing' && mailingName) {
      result += (mailingName + ', ');
    }
    
    if (item.address1) {
      result += (item.address1);
    }
    if (item.address2) {
      result += (' ' + item.address2);
    }
    if (item.city) {
      result += (', ' + item.city);
    }
    if (item.stateCode && !item.state) {
      result += (', ' + item.stateCode);
    }
    if (!item.stateCode && item.state) {
      result += (', ' + item.state);
    }
    if (item.stateCode && item.state) {
      result += (', ' + item.stateCode);
    }
    if (item.postalCode) {
      result += (', ' + item.postalCode);
    }
    if (item.country) {
      (isFullCountryName && item.countryFullName) ? result += (', ' + item.countryFullName) : result += (', ' + item.country);
    }
  }

  return result;
}

function getPersons(item, language=window.globalLanguage.lang) {
  let result = '';
  // let personType = item.personType;

  // if (personType && window.qResultsLang.relationshipLabel) {
  //   personType = window.qResultsLang.relationshipLabel[item.personType] || item.personType;
  //   result += (personType + ' / ' + (item.firstName || '') + ' ' + (item.lastName || ''));
  // } else {
    result += ((item.firstName || '') + ' ' + (item.lastName || ''));
  // }

  if(item.age || item.age == 0) {
    if(language === 'zh') {
      result += ' / '+ item.age +' 岁';
    } else if (language === 'es') {
      result += ' / '+ item.age +' Años';
    } else {
      result += ' / '+ item.age +' Years';
    }
  }
  if(item.gender && (item.gender.toLowerCase().startsWith('m'))) {
      if(language === 'zh') {
        result += ' / 男';
      } else if (language === 'es') {
        result += ' / Masculino';
      } else {
        result += ' / Male';
      }
  } else if(item.gender && (item.gender.toLowerCase().startsWith('f'))) {
      if(language === 'zh') {
        result += ' / 女';
      } else if (language === 'es') {
        result += ' / Femenino';
      } else {
        result += ' / Female';
      }
  }

  return result;
}

//Set Maximum length for Address Book Selector addresses
function getAddrLength(addrBook, width) {
  const MaxLengthForUpperCase = 40;
  const MaxLengthForLowerCase = 47;

  const LargeMaxLengthForUpperCase = 80;
  const largeMaxLengthForLowerCase = 87;

  let addrs = addrBook.map((item, index) => {
    let result = getAddressStr(item, item.addressType, true);

    if(width <= 900) {
      if (result.toUpperCase() === result) {
        if (result.length > MaxLengthForUpperCase) {
          result = (result.slice(0, MaxLengthForUpperCase) + '...');
        }
      } else {
        if (result.length > MaxLengthForLowerCase) {
          result = (result.slice(0, MaxLengthForLowerCase) + '...');
        }
      }
    } else {
      if (result.toUpperCase() === result) {
        if (result.length > LargeMaxLengthForUpperCase) {
          result = (result.slice(0, LargeMaxLengthForUpperCase) + '...');
        }
      } else {
        if (result.length > largeMaxLengthForLowerCase) {
          result = (result.slice(0, largeMaxLengthForLowerCase) + '...');
        }
      }
  }
    return result;
  });

  return addrs;
}

function isChromeBrowser() {
  const userAgentBrow = window.navigator.userAgent;
  return userAgentBrow.indexOf("Chrome") != -1;
}

function phoneNumSetup (phoneNumber, options, isPhone2) {
  if(typeof options !== 'object') options = {};

  // set Default option and override them with provided ones
  options = {
    disableDomUpdate: false,
    ...options
  }
  
  let isdCode = null;
  // make sure we get correct format, eg. +1-1231231234, +1-123-123-1234
  if (phoneNumber && phoneNumber.indexOf('-') > 0 && phoneNumber.indexOf('+') === 0) {
    let list = phoneNumber.split('-');
    let cCode = list[0].split('(');
    isdCode = cCode[0].substring(1);
    list = list.splice(1);

    if (list.length > 1) {
      phoneNumber = list.join('');
    } else {
      phoneNumber = list.join();
    }
  } else {
    return { phoneNumber, countryCode : "USA" };
  }
  
  let countryCode =  isdCode ? $("select option[isd-code=" + isdCode + "]").attr("cntr-code") : null;
  if (countryCode == "CAN") {
    countryCode = "USA";
  }

  let phnCodeElem = isPhone2 ? $("#phnCode2") : $("#phnCode");
  if (phnCodeElem && !options.disableDomUpdate) {
    phnCodeElem.val(countryCode).trigger('change');
  }
  
  return { phoneNumber, countryCode };
}

function phoneNumberSplit(phoneNumber) {
  if (phoneNumber && phoneNumber.indexOf('-') > 0 && phoneNumber.indexOf('+') === 0) {
    let list = phoneNumber.split('-');
    let cCode = list[0].split('(');
    let isdCode = cCode[0].substring(1);
    list = list.splice(1);

    if (list.length > 1) {
      phoneNumber = list.join('');
    } else {
      phoneNumber = list.join();
    }
    return { phoneNumber, isdCode : isdCode };
  } else {
    return { phoneNumber, isdCode : "1" };
  }
}

function getValidAddresses(addresses, addressType, countryStatusList, stateStatusList, carriedCountryFromStep1, carriedStateFromStep1) {
  let blockedCountries = [];
  let blockedStates = [];

  let onlyAllowCountries = [];
  let onlyAllowStates = [];

  if (addressType === 'home') {
    countryStatusList = countryStatusList.filter(e => e.type === 'Home Country');
    stateStatusList = stateStatusList.filter(e => e.type === 'Residence');
  } else if (addressType === 'mailing') {
    countryStatusList = countryStatusList.filter(e => e.type === 'Mailing Address Country');
    stateStatusList = stateStatusList.filter(e => e.type === 'Mailing');
  } else if (addressType === 'billing') {
    countryStatusList = countryStatusList.filter(e => e.type === 'Billing Address Country');
    stateStatusList = stateStatusList.filter(e => e.type === 'Billing');
  }

  if (addressType === 'home') {
    // Home Country
    if (countryStatusList && countryStatusList.length > 0) {
      let filterResult = countryStatusList.filter(e=>e.type === 'Home Country').map(e=>e.country);
      if (countryStatusList && countryStatusList[0]) {
        if (countryStatusList[0].status === 'Excluded') {
          blockedCountries = filterResult;
        } else {
          onlyAllowCountries = filterResult;
        }
      }
    }
    // Home State
    if (stateStatusList && stateStatusList.length > 0) {
      let filterResult = stateStatusList.filter(e => e.type === 'Physical Presence' || e.type === 'Residence').map(e=>e.state);
      if (stateStatusList && stateStatusList[0]) {
        if (stateStatusList[0].status === 'Excluded') {
          blockedStates = filterResult;
        } else {
          onlyAllowStates = filterResult;
        }
      }
    }
  }

  if (addressType === 'mailing') {
    // Mailing Country
    if (countryStatusList && countryStatusList.length > 0) {
      let filterResult = countryStatusList.filter(e=>e.type === 'Mailing Address Country').map(e=>e.country);
      if (countryStatusList && countryStatusList[0]) {
        if (countryStatusList[0].status === 'Excluded') {
          blockedCountries = filterResult;
        } else {
          onlyAllowCountries = filterResult;
        }
      }
    }
    // Mailing State
    if (stateStatusList && stateStatusList.length > 0) {
      let filterResult = stateStatusList.filter(e=>e.type === 'Mailing').map(e=>e.state);
      if (stateStatusList && stateStatusList[0]) {
        if (stateStatusList[0].status === 'Excluded') {
          blockedStates = filterResult;
        } else {
          onlyAllowStates = filterResult;
        }
      }
    }
  }

  if (addressType === 'billing') {
    // Billing Country
    if (countryStatusList && countryStatusList.length > 0) {
      let filterResult = countryStatusList.filter(e=>e.type === 'Billing Address Country').map(e=>e.country);
      if (countryStatusList && countryStatusList[0]) {
        if (countryStatusList[0].status === 'Excluded') {
          blockedCountries = filterResult;
        } else {
          onlyAllowCountries = filterResult;
        }
      }
    }
    // Billing State
    if (stateStatusList && stateStatusList.length > 0) {
      let filterResult = stateStatusList.filter(e=>e.type === 'Billing').map(e=>e.state);
      if (stateStatusList && stateStatusList[0]) {
        if (stateStatusList[0].status === 'Excluded') {
          blockedStates = filterResult;
        } else {
          onlyAllowStates = filterResult;
        }
      }
    }
  }
  if (addresses) {
    if (blockedCountries && blockedCountries.length > 0) {
      addresses = addresses.filter(e=>blockedCountries.indexOf(e.country) === -1);
    }
    if (blockedStates && blockedStates.length > 0) {
      addresses = addresses.filter(e=>blockedStates.indexOf(e.state) === -1);
    }
    if (onlyAllowCountries && onlyAllowCountries.length > 0) {
      addresses = addresses.filter(e=>onlyAllowCountries.indexOf(e.country) >= 0);
    }
    if (onlyAllowStates && onlyAllowStates.length > 0) {
      addresses = addresses.filter(e=>onlyAllowStates.indexOf(e.state) >= 0);
    }
    if(carriedCountryFromStep1) {
      addresses = addresses.filter(e=>e.country === carriedCountryFromStep1);
    }
    if (carriedStateFromStep1) {
      addresses = addresses.filter(e=>e.state === carriedStateFromStep1);
    }
  }
  
  return addresses;
}

function getCategoryName(categorySection, qResultsLang) {
  switch(categorySection) {
    case 'visitorUSA':
      return qResultsLang.visitInsur;
    case 'studyAbroad':
      return qResultsLang.studyAbroad;
    case 'schengen':
      return qResultsLang.schengen;
    case 'opt':
      return qResultsLang.opt;
    case 'multitrip':
      return qResultsLang.multiTrip;
    case 'travelOutsideUSA':
      return qResultsLang.travelOutsideUSA;
    case 'student':
      return qResultsLang.student;
    case 'newImmigrants':
      return qResultsLang.newImmigrants;
    case 'trip':
      return qResultsLang.trip;
    case 'missionary':
      return qResultsLang.missionary;
    case 'missionaryTravel':
      return qResultsLang.missionaryTravel;
    case 'j1Visa':
      return qResultsLang.j1Visa;
    case 'flight':
      return qResultsLang.flight;
    case 'expatriate':
      return qResultsLang.expatriate;
    case 'groupTravelMedical':
      return qResultsLang.groupTravelMedical;
    case 'marine':
      return qResultsLang.marine;
    case 'covid19':
      return qResultsLang.covid19;
    case 'greenCardVisitor':
      return qResultsLang.greenCardVisitor;
    default:
      return categorySection;
  }
}

function validateJson (data) {
  try {
    for (const key in data) {
      if (key !== 'phoneNumber') {
        let thisval = data[key];
        if (thisval && typeof thisval == 'string') {
          data[key] = thisval.replace(/[^\w\s-/&@.]/g,'');
        } else if (thisval && typeof thisval === 'object' && !Array.isArray(thisval)) {
          data[key] = validateJson(thisval);
        } else if (thisval && typeof thisval === 'object' && Array.isArray(thisval)) {
          thisval.forEach((elm, i) => {
            if (elm.hasOwnProperty('itemPrices')) delete thisval[i].itemPrices;
            if (elm.hasOwnProperty('riderPrices')) delete thisval[i].riderPrices;
            if (elm.hasOwnProperty('bundleRiderPrices')) delete thisval[i].bundleRiderPrices;
            if (elm && typeof elm === 'object') {
              thisval[i] = validateJson(elm);
            } else if (elm) {
              thisval[i] = elm.replace(/[^\w\s-/&@.]/g,'');
            }
          });
          data[key] = thisval;
        }
      }
    }
    return data;
  } catch (err) {
    console.log(err);
  }
}

let errorTriggered = false;
/**
 * 
 * @param {Object} http 
 * @param {Object} data 
 * @param {Function} fn 
 * @param {Boolean} errored 
 * @param {String} tab 
 */
function saveApplication(http, data, fn, errored, tab) {
  data = createDateByLangApplications(data, window.globalLanguage.lang);
  if (errored) errorTriggered = true;
  try {
    let jsonData = JSON.parse(JSON.stringify(data));
    // if (!errored) jsonData = validateJson(jsonData);
    if (http && http?.post && typeof http.post === 'function') {
      const dataObj = errored ? {"payload": JSON.stringify(jsonData)} : JSON.stringify(jsonData);
      http.post('/api/myaccount/save-intermediate-application-step3/?t='+tab, dataObj)
      .then(response => {
        if (response && response.body && response.body.error) {
           if (!errorTriggered)saveApplication (http, jsonData, fn, true, tab);
           console.error(response.body.error);
        } else {
          if (fn && typeof fn === 'function') {
            fn();
          }
        }
      }, err => {
        if (!errorTriggered)saveApplication (http, jsonData, fn, true, tab);
        else {
          if (fn && typeof fn === 'function') {
            fn();
          }
        }
        console.error(err);
      })
    }
  } catch (error) {
    console.log(error);
    if (fn && typeof fn === 'function') {
      fn();
    }
  }
}

function isPrimaryByTravelerType(travelerType) {
  return travelerType === '' || travelerType === 'primary';
}

function isSpouseByTravelerType(travelerType) {
  return travelerType === 'spouse';
}

function isChildByAge(productCode, age) {
  if (productCode === 'IMG_406' || productCode === 'IMG_532' || productCode === 'IMG_546') {
    return age < 19;
  } else {
    return age < 18;
  }
}

function isChildByTravelerType(travelerType) {
  return (travelerType === 'child') || (!isPrimaryByTravelerType() && !isSpouseByTravelerType() && !travelerType);
}

function myAccountLogout() {
  let apiUrl = '/myaccount/logout/';
  let lang = window.globalLanguage.lang;
  let withLangUrl = apiUrl + "?lang=" + lang;

  sessionStorage.removeItem('serviceState');

  this.$http.get(withLangUrl). then(res => {
    mobileAppLogout();

    let path = '/myaccount/';
    
    switch(lang) {
      case 'zh':
        path = '/wodezhanghu/';
        break;
      case 'es':
        path = '/micuenta/';
        break;
      default:
        break;
    }

    setTimeout(() => {
      window.location.pathname = path;
    }, 100);
  }).catch(() => console.log('Logout Failed.'));
}

function isSameStringValue(str1, str2) {
    let isStr1Blank = !str1;
		let isStr2Blank = !str2;
    
    // special case
    if ((str1 === '' && str2 === null) || (str1 === null && str2 === '')) return true;
		if (isStr1Blank != isStr2Blank) return false;
		if (isStr1Blank == true && isStr2Blank == true) return true;
		
		return str1 === str2;
}

function isTruthy(x) {
  return x === true;
}
function sortByAlphabeticalOrder(obj, propName) {
  if (obj && obj.length > 1) {
    obj.sort((a, b) => {
      if (a[propName] && b[propName]) {
        let ap = a[propName].toLowerCase();
        let bp = b[propName].toLowerCase();
        if(ap < bp) { return -1; }
        if(ap> bp) { return 1; }
      }
      return 0;
    });
  }
}

function getFormattedPersonName(person) {
  if (!person) return;

  let res = person.firstName;

  if (person.middleName) {
    res += (!res ? person.middleName : ' ' + person.middleName);
  }
  if (person.lastName) {
    res += (!res ? person.lastName : ' ' + person.lastName);
  }

  return res;
}

function deepCopy(obj) {
  return JSON.parse(JSON.stringify(obj));
}

function noSplCharacters (val) {
  if(val) {
    return /^[^<>%$@,.\/\\!#\^&*\(\)\[\]\{\}\-_=+0-9~`"]*$/g.test(val);
  } else {
    return true;
  }
}

function isTemporaryDisabledProduct(productCode) {
  if (!productCode) return false;
  if (productCode.indexOf('TravelInsured') > -1 || productCode == 'Trawick_636' || productCode == 'Trawick_635' || productCode == 'Trawick_724') return true;
  return false;
}

function shouldCheckInsuredIDPerPerson(policy) {
  return policy.companyCode === 'IMG' && policy.certificateType && policy.certificateType.toLowerCase() === 'group';
}

function getFormattedDurationText(duration, monthlyPricingPlan, qResultsLang, isEligibleCoverage, noEligibleDays) {
  if (!duration) {
    return `0 ${qResultsLang.days}`;
  } else {
    let months = duration.months;
    let days = duration.days;
    let totalDays = duration.totalDays;

    if (isEligibleCoverage && noEligibleDays) {
      days -= noEligibleDays;
      totalDays -= noEligibleDays;
    }

    if (monthlyPricingPlan) {
      let str = '';

      // MYACCOUNT-1062 MYACCOUNT-1041
      if (isEligibleCoverage && (days === 30 || days === 31)) {
        months += 1;
        days = 0;
      }

      if (months > 0) {
        if (months != 1) {
          str += `${months} ${qResultsLang.months}`;
        } else {
          str += `${months} ${qResultsLang.month}`;
        }
      }

      if (days > 0) {
        if (days != 1) {
          if (months > 0) {
            str += `, ${days} ${qResultsLang.days}`;
          } else {
            str += `${days} ${qResultsLang.days}`;
          }
        } else if (days === 1){
          if (months > 0) {
            str += `, 1 ${qResultsLang.day}`;
          } else {
            str += `1 ${qResultsLang.day}`;
          }
        }
      }

      return str || `0 ${qResultsLang.days}`;
    } else {
      if (totalDays != 1) {
        return `${totalDays} ${qResultsLang.days}`;
      } else {
        return `${totalDays} ${qResultsLang.day}`;
      }
    }
  }
}

function getRefundDetailsSetup(data, cancelledCerts, qResultsLang, isWaiveCancellationFee, certificateNumbers, companyCode) {
  let cancellationFee = isWaiveCancellationFee ? 0 : data.cancellationFee;
  let totalLeftOver = 0;
  let noSupport = false;
  let monthlyPricingPlan = data.monthlyPricingPlan;
  let firstPartialRefund = null;
  let nonrefundableAmount = null;
  let noEligibleDays = 0;

  // most of them should be calculated for partial refund
  if (data.refundDTOList && data.refundDTOList.length) {
    // extension fee charged maybe
    let totalExtraFee = 0;

    data.refundDTOList.forEach(refund => {
      noEligibleDays += (refund.noEligibleDays || 0);

      if (refund.noSupport) {
        noSupport = true;
      }
      let total = 0;
      refund.travelersList.forEach(traveler => {
          if (cancelledCerts && cancelledCerts.length) {
              if (traveler.certNumber && cancelledCerts.indexOf(traveler.certNumber) > -1) {
                total += traveler.refundAmount;
              }
          } else {
            total += traveler.refundAmount;
          }
      });

      if (firstPartialRefund === null) {
        firstPartialRefund = total;
      }

      totalLeftOver += total;
      totalExtraFee += refund.extraFees;
    });

    totalLeftOver -= totalExtraFee;
  }
  
  if (data.fullRefundPremiumList && data.fullRefundPremiumList.length) {
    data.fullRefundPremiumList.forEach(premium => {
      totalLeftOver += premium;
    });
  }
  
  let duration = getFormattedDurationText(data.coverageDuration, monthlyPricingPlan, qResultsLang, false, noEligibleDays);
  let totalRefund = 0;

  if (data.lookAsIndependentPolicy && firstPartialRefund) {
    let firstPartialRefundTotal = Math.max(firstPartialRefund - cancellationFee, 0);
    totalRefund = totalLeftOver - firstPartialRefund + firstPartialRefundTotal;

    if (firstPartialRefund - cancellationFee < 0) {
      nonrefundableAmount = firstPartialRefund;

      // MYACCOUNT-1059
      if (data.fullRefundPremiumList && data.fullRefundPremiumList.length) {
        cancellationFee = 0;
      } else {
        // MYACCOUNT-1090
        nonrefundableAmount = null;
      }
    }
  } else {
    totalRefund = totalLeftOver - cancellationFee;
  }

  if (!data.mustPayDifferece) {
    totalRefund = Math.max(totalRefund, 0);
  }
  
  let eligibleDuration = getFormattedDurationText(data.eligibleDuration, monthlyPricingPlan, qResultsLang, true, noEligibleDays);
  let personLevelRefund = null;
  let personLevelRefundDiff = false;

  // Showing duration by person level
  if (data.mayDiffCoverageDateByperson && data.policyRefundDurationList && data.policyRefundDurationList.length) {
    personLevelRefund = {};

    data.policyRefundDurationList.forEach(refundDuration => {
          if (refundDuration.personsId && refundDuration.personsId.length) {
              refundDuration.personsId.forEach(personId => {
                  if (certificateNumbers.indexOf(personId) > -1) {
                      if (personLevelRefund[personId]) {
                          let coverageYears = personLevelRefund[personId].coverageDuration.years + refundDuration.coverageDuration.years;
                          let coverageMonths = personLevelRefund[personId].coverageDuration.months + refundDuration.coverageDuration.months;
                          let coverageDays = personLevelRefund[personId].coverageDuration.days + refundDuration.coverageDuration.days;
                          let coverageTotalDays = personLevelRefund[personId].coverageDuration.totalDays + refundDuration.coverageDuration.totalDays;

                          let eligibleYears = personLevelRefund[personId].coverageDuration.years + refundDuration.coverageDuration.years;
                          let eligibleMonths = personLevelRefund[personId].coverageDuration.months + refundDuration.coverageDuration.months;
                          let eligibleDays = personLevelRefund[personId].coverageDuration.days + refundDuration.coverageDuration.days;
                          let eligibleTotalDays = personLevelRefund[personId].coverageDuration.totalDays + refundDuration.coverageDuration.totalDays;

                          personLevelRefund[personId] = {
                              coverageDuration: {
                                  years: coverageYears,
                                  months: coverageMonths,
                                  days: coverageDays,
                                  totalDays: coverageTotalDays,
                              },
                              eligibleDuration: {
                                  years: eligibleYears,
                                  months: eligibleMonths,
                                  days: eligibleDays,
                                  totalDays: eligibleTotalDays,
                              },
                          }; 
                      } else {
                          personLevelRefund[personId] = {
                              coverageDuration : refundDuration.coverageDuration,
                              eligibleDuration : refundDuration.eligibleDuration,
                          }
                      }
                  }
              });
          }
      });

      // Check whether they have different duration
      let personsId = Object.keys(personLevelRefund);
      if (personLevelRefund && personsId.length > 0) {
          let firstCoverageDuration = null;
          let firstEligibleDuration = null;

          for (let i = 0; i < personsId.length; i++) {
            let currentPerson = personLevelRefund[personsId[i]];

            if (i === 0) {
              firstCoverageDuration = currentPerson.coverageDuration;
              firstEligibleDuration = currentPerson.eligibleDuration;
            } else {
              if (!isSameDuration(firstCoverageDuration, currentPerson.coverageDuration)) {
                  personLevelRefundDiff = true;
              }
              if (!isSameDuration(firstEligibleDuration, currentPerson.eligibleDuration)) {
                  personLevelRefundDiff = true;
              }
            }

            currentPerson.coverageDuration.text = getFormattedDurationText(currentPerson.coverageDuration, monthlyPricingPlan, qResultsLang, false, noEligibleDays);
            currentPerson.eligibleDuration.text = getFormattedDurationText(currentPerson.eligibleDuration, monthlyPricingPlan, qResultsLang, true, noEligibleDays);
          }
      }
  }

  return {
    cancellationFee,
    totalLeftOver: Math.max(totalLeftOver, 0),
    duration,
    totalRefund,
    eligibleDuration,
    personLevelRefund,
    personLevelRefundDiff,
    noSupport,
    nonrefundableAmount,
  }
}

function isSameDuration(duration1, duration2) {
  if (duration1 && duration2) {
    return duration1.years === duration2.years && duration1.months === duration2.months &&
    duration1.days === duration2.days && duration1.totalDays === duration2.totalDays;
  }
  return false;
}

function multiLanguageDisabedCompanies(tripPlan) {
  let companies =  ['GU', 'Travelex', 'iTI', 'TravelInsured', 'Careington']
  if(tripPlan){
    companies.push('Trawick');
  }
  return companies;
}

function checkAgent(app, isStep1) {
  let appModalVisited = getItemSessionStorage("appModalVisited");
  
    if(window.globalLanguage.internalIP && (window.globalLanguage.affiliateid !== 'none' || window.globalLanguage.myAccountLogin)) {
      console.log("app ", app[0], appModalVisited);

      if(app[0] && (!appModalVisited || isStep1)) {

        setItemSessionStorage("fromApp", true);
        sessionStorage.setItem("isShowLogin", false);
        // this.requestLoginModal();
        if (ctsAddCall) ctsAddCall.showAffiliate = true;
      } else if(!app[0]) {
        sessionStorage.setItem("isShowLogin", false);
        // this.requestLoginModal();
        if (ctsAddCall) ctsAddCall.showAffiliate = true;
      }
  }
}

function getIMGTripPlanIds() {
  return [675, 676, 681,683,684,685,682,678,679,680,677];
}

function getOldITIPlanIds() {
  return [589, 580, 584, 590, 592, 585, 586, 588, 591, 608, 607];
}

function isDiscontinuePlan(productCode, type) {
  let discontinuePlans = ['Trawick_634', 'IMG_312', 'Trawick_506', 'Trawick_583', 'Trawick_556', 'IMG_1', 'Travelex_362', 'Travelex_361', 'Travelex_672'];
  if (!type || type != 'cancellation') discontinuePlans.push('Trawick_656');
  if (discontinuePlans.indexOf(productCode) > -1 || productCode.indexOf('TravelInsured_') > -1) return true;
  return false;
}

function getShippingAmount(application, mailingAddress) {
  if (application.companyCode === 'HCCMIS' || application.companyCode === 'WorldTrips') {
    return application.fulfillmentType === 4 ? 30 : application.fulfillmentType === 1 ? 20 : 0;
  } else if (application.companyCode === 'ARS') {
    if(application.fulfillmentType == 1 && mailingAddress && mailingAddress.country == 'USA') {
      return 25;
    }else if(application.fulfillmentType == 4 && mailingAddress && mailingAddress.country !== 'USA') {
        return 35;
    } else {
        return 0;
    }
  } else if (application.companyCode === 'IMG') {
    let patriotTMPlans = [4, 21, 319, 320, 563, 751, 752, 755];
    if(application.fulfillmentType === 1) {
      if(patriotTMPlans.indexOf(application.planId) > -1) {
        return 50;
      } else {
        return 20;
      }
    } else {
      return 0;
    }
  }

  return 0;
}

function getCalculateTotalPremium(application, computedShippingAmount, plan) {
  if (application == null || typeof application != 'object') return 0;
  if (application.companyCode === 'HCCMIS' || application.companyCode === 'WorldTrips') {
    return ((application.totalPrice - computedShippingAmount) * plan.scMonths)+computedShippingAmount;
  } else if (application.companyCode === 'ARS') {
    return ((application.totalPrice - computedShippingAmount) * plan.scMonths)+computedShippingAmount;
  } else if (application.companyCode === 'IMG') {
    let calculateShaMonths = 0;

    if (application.appCompanyImg && application.appCompanyImg.shaPaymentType === 2 && application.planResult.calcDays > 0) {
			calculateShaMonths = plan.calcMonths+1;
		} else {
      calculateShaMonths = plan.calcMonths;
    }

    let total = (application.totalPrice - computedShippingAmount) * calculateShaMonths;
    total += computedShippingAmount;
    return total;
  }

  return 0;
}

function isAfterToday(date, lang = window.window.globalLanguage.lang) {
  if (!date) return false;
  let dateToCheck = createDateByLangStr(date, lang);
  return dayjs(dateToCheck, 'MM/DD/YYYY').diff(dayjs().format('MM/DD/YYYY')) > 0;
}

function onlyCancelimmedite(companyCode, cancellationType) {
  return companyCode === 'Trawick' || cancellationType === 'Type18' || cancellationType === 'Type23';
}

function memberBased(companyCode) {
  return companyCode === 'Careington';
}

function isSafari() {
  return /constructor/i.test(window.HTMLElement) ||
  (function(p) {
    return p.toString() === "[object SafariRemoteNotification]";
  })(!window["safari"] || safari.pushNotification);
}

function isFirefox() {
  let firefoxDetected = false;
  if (navigator.userAgent) firefoxDetected = navigator.userAgent.toLowerCase().indexOf('firefox') > -1 ? true : false;
  return firefoxDetected;
}

function dateNotBefore(date) {
    return function (val) {
        if(val && window.globalLanguage){
          var dateToCheck1 = createDateByLangStr(val);
          var dateToValidate = new Date(date);
          return dateToValidate.setHours(0,0,0,0) <= dateToCheck1.setHours(0,0,0,0);
        }
        else{
            return true;
        }
    }
}

function getOS() {
  var userAgent = window.navigator.userAgent,
      platform = window.navigator.platform,
      macosPlatforms = ['Macintosh', 'MacIntel', 'MacPPC', 'Mac68K'],
      windowsPlatforms = ['Win32', 'Win64', 'Windows', 'WinCE'],
      iosPlatforms = ['iPhone', 'iPad', 'iPod'],
      os = null;

  if (macosPlatforms.indexOf(platform) !== -1) {
    os = 'Mac OS';
  } else if (iosPlatforms.indexOf(platform) !== -1) {
    os = 'iOS';
  } else if (windowsPlatforms.indexOf(platform) !== -1) {
    os = 'Windows';
  } else if (/Android/.test(userAgent)) {
    os = 'Android';
  } else if (!os && /Linux/.test(platform)) {
    os = 'Linux';
  }

  return os;
}

function isIOSChrome() {
  if (getOS() === 'iOS') {
    // https://chromium.googlesource.com/chromium/src/+/HEAD/docs/ios/user_agent.md
    return window.navigator.userAgent.indexOf('CriOS/') > -1;
  }

  return false;
}

const debounce = (callback, wait) => {
  let timeoutId = null;
  return (...args) => {
    window.clearTimeout(timeoutId);
    timeoutId = window.setTimeout(() => {
      callback.apply(null, args);
    }, wait);
  };
}

const resetMobileMenu =  debounce (()=>{
  var viewportWidth = $(window).width();

    if($('body').hasClass('pushy-open-right') && viewportWidth > 993) {
       $('body').removeClass('pushy-open-right');
    }
}, 500);

function dateInsert(val, oldVal, cursorIdx) {
  var lang = window.globalLanguage.lang;
  let newVal = val;
  
  if ((oldVal && newVal && oldVal.length < newVal.length) || (!oldVal && newVal)) {
    /* ZH date format. Auto insert '-' to date input "yyyy-mm-dd" */
    if(lang === 'zh') { 
      // When editing inbetween '/'
      if ((cursorIdx < 3 && newVal.length > 4) || (cursorIdx == 5 && newVal[4] == '-' && newVal[6] == '-')) {
        return newVal;
      }
      if (newVal.length == 6) {
        if (newVal[5] > 1) {
          newVal = [newVal.slice(0, 5), 0, newVal.slice(5), '-'].join('');
        }
      }
      else if (newVal.length == 7) {
        if (newVal[6] == '-') {
          newVal = [newVal.slice(0, 5), 0, newVal.slice(5)].join('');
        }
        else if (newVal[5] == 1 && newVal[6] > 3) {
          newVal = [newVal.slice(0, 5), 0, newVal[5], 0, newVal[6]].join('');
        }
        else if (newVal[5] == 1 && newVal[6] == 3) {
          newVal = [newVal.slice(0, 5), 0, newVal[5], newVal[6]].join('');
        }
      }
      else if (newVal.length == 5 && newVal[4] != '-' && newVal[4] > 3) {
        newVal = [newVal.slice(0, 4), 0, newVal.slice(4)].join('');
      }
      else if ((newVal.length == 8 && newVal[7] > 3) || (newVal.length == 9 && newVal[8] > 3)) {
        newVal = [newVal.slice(0, 7), 0, newVal.slice(7)].join('');
      }
      if (newVal.length > 3) {
        let tempVal = newVal ? newVal.replace(/-/g, '') : '';
        newVal = tempVal.length < 6 ? [tempVal.slice(0, 4), '-', tempVal.slice(4)].join('') : [tempVal.slice(0, 4), '-', tempVal.slice(4, 6), '-', tempVal.slice(6)].join('');

        if (newVal.length > 10) {
          newVal = newVal.slice(0, 10);
        }
      }
    }
    /* EN date format. Auto insert '/' to date input "mm/dd/yyyy" */
    else if (lang === 'en') {
      if (newVal.length == 1) {
        if (newVal > 1) {
          newVal = [0, newVal, '/'].join('');
        }
      }
      // When editing inbetween '/'
      if ((cursorIdx == 0 && newVal[1] == '/') || (cursorIdx == 3 && newVal[2] == '/' && newVal[4] == '/')) {
        return newVal;
      }
      else if (newVal.length == 2) {
        if (newVal[1] == '/') {
          newVal = [0, newVal].join('');
        }
        else if (newVal[0] == 1 && newVal[1] > 3) {
          newVal = [0, newVal.slice(0, 1), 0, newVal.slice(1)].join('');
        }
        else if (newVal[0] == 1 && newVal[1] == 3) {
          newVal = [0, newVal.slice(0, 1), newVal.slice(1)].join('');
        }
      }
      else if (newVal.length == 3 && newVal[2] != '/' && newVal[2] > 3) {
        newVal = [newVal.slice(0, 2), 0, newVal.slice(2)].join('');
      }
      else if ((newVal.length == 4 && newVal[3] > 3) || (newVal.length == 5 && newVal[4] == '/')) {
        newVal = [newVal.slice(0, 3), 0, newVal.slice(3)].join('');
      }
      // Corrects format / move numbers
      if (newVal.length > 1) {
        let tempVal = newVal ? newVal.replace(/\//g, '') : '';
        newVal = tempVal.length < 4 ? [tempVal.slice(0, 2), '/', tempVal.slice(2)].join('') : [tempVal.slice(0, 2), '/', tempVal.slice(2, 4), '/', tempVal.slice(4)].join('');

        if (newVal.length > 10) {
          newVal = newVal.slice(0, 10);
        }
      }
    }
    /* ES date format. Auto insert '/' to date input "dd/mm/yyyy" */
    else if (lang === 'es') { 
      if (newVal.length == 1) {
        if (newVal > 3) {
          newVal = [0, newVal, '/'].join('');
        }
      }
      // When editing inbetween '/'
      if ((cursorIdx == 0 && newVal[1] == '/') || (cursorIdx == 3 && newVal[2] == '/' && newVal[4] == '/')) {
        return newVal;
      }
      else if (newVal.length == 2) {
        if (newVal[1] == '/') {
          newVal = [0, newVal].join('');
        }
        else if (newVal[0] == 3 && newVal[1] > 1) {
          newVal = [0, newVal.slice(0, 1), 0, newVal.slice(1)].join('');
        }
      }
      else if (newVal.length == 3 && newVal[2] != '/' && newVal[2] > 1) {
        newVal = [newVal.slice(0, 2), 0, newVal.slice(2)].join('');
      }
      else if ((newVal.length == 4 && newVal[3] > 1) || (newVal.length == 5 && newVal[4] == '/')) {
        newVal = [newVal.slice(0, 3), 0, newVal.slice(3)].join('');
      }
      // Corrects format / move numbers
      if (newVal.length > 1) {
        let tempVal = newVal ? newVal.replace(/\//g, '') : '';
        newVal = tempVal.length < 4 ? [tempVal.slice(0, 2), '/', tempVal.slice(2)].join('') : [tempVal.slice(0, 2), '/', tempVal.slice(2, 4), '/', tempVal.slice(4)].join('');

        if (newVal.length > 10) {
          newVal = newVal.slice(0, 10);
        }
      }
    }
  }
  return newVal;
}

function getCountriesFromStorage () {
  let masterList = getItemSessionStorage(`masterList_${window.globalLanguage.lang}`);
  masterList = masterList ? JSON.parse(masterList) : null;
  return masterList;
}

function getCurrentTime (date, lang) {
  let time = null;
  switch (lang) {
    case 'zh':
      time = convertToCSTTime(date).locale('zh').set('second', 0).format('h:mm A');
      break;
  
    default:
      time = convertToCSTTime(date).set('second', 0).format('h:mm A');
      break;
  }

  return time;
}

function getTimeInGivenLang (time, lang) {
  let translatedTime = '';
  translatedTime = convertToCSTTime(date).set('second', 0).format('h:mm A');
  return translatedTime;
}

function addMins(date, minsToAdd) {
  let newDateTime = convertToCSTTime(date).add(minsToAdd, 'minute');
  return {
    fullTime: newDateTime,
    hhmm: convertToCSTTime(newDateTime).format('h:mm A')
  }
}

function convertToCSTTime (date) {
  if (!date) date = new Date();
  let convertedDate = dayjs(date);
  try {
    convertedDate = dayjs(date).tz('America/Chicago');
    return convertedDate;
  } catch(e) {
    //
  }
  try {
    convertedDate = dayjs(date).tz('CDT');
    return convertedDate;
  } catch(e) {
    //
  }
  return dayjs(date);
}

const setStateType = (states) => {
  const stateTypes = { M: ["AA", "AE", "AP"], T: ["AS", "FM", "GU", "MH", "MP", "PW", "PR", "VI"]};

  const newStateList = states.map(st => {
    st.type = stateTypes["M"].indexOf(st.code) > -1 ? "M" : stateTypes["T"].indexOf(st.code) > -1 ? "T" : "S";
    return st;
  })

  return newStateList;
}

function matchTimezone (val) {
  if (!val || typeof val != 'string') return null;
  const allTimezones = [{key: "chathamIslands",value:"Pacific/Chatham"},{key: "fiji",value:"Pacific/Fiji"},{key: "noumea",value:"Pacific/Noumea"},{key: "lordHoweIsland",value:"Australia/Lord_Howe"},{key: "brisbane",value:"Australia/Brisbane"},{key: "southAustralia",value:"Australia/Adelaide"},{key: "japan",value:"Asia/Tokyo"},{key: "westernAustralia",value:"Australia/Eucla"},{key: "china",value:"Asia/Shanghai"},{key: "bangkok",value:"Asia/Bangkok"},{key: "yangon",value:"Asia/Rangoon"},{key: "bangladesh",value:"Asia/Dhaka"},{key: "nepal",value:"Asia/Kathmandu"},{key: "india",value:"Asia/Kolkata"},{key: "turkmenistan",value:"Asia/Ashgabat"},{key: "dubai",value:"Asia/Dubai"},{key: "Iran",value:"Iran"},{key: "baghdad",value:"Asia/Baghdad"},{key: "libya",value:"Africa/Tripoli"},{key: "cut",value:"CET"},{key: "gmt",value:"Etc/Greenwich"},{key: "cabo",value:"Atlantic/Cape_Verde"},{key: "sg",value:"Atlantic/South_Georgia"},{key: "saoPaulo",value:"America/Sao_Paulo"},{key: "atlantic",value:"America/Cuiaba"},{key: "eastern",value:"America/New_York"},{key: "central",value:"America/Chicago"},{key: "mountain",value:"America/Denver"},{key: "pacific",value:"America/Los_Angeles"},{key: "alaska",value:"US/Alaska"},{key: "marquesasIslands",value:"Pacific/Marquesas"},{key: "hawaii",value:"Pacific/Tahiti"},{key: "niue",value:"Pacific/Niue"}];
  const selectedZone = allTimezones.filter(timezone=> typeof(timezone.value?.toLowerCase) == 'function' && typeof(val?.toLowerCase) == 'function' && timezone.value.toLowerCase()===val.toLowerCase());
  if (selectedZone && selectedZone.length) return qResultsLangGlobal.contactTime[selectedZone[0].key];
  else return null;
}

function isDateBeforeOrEqual(dateOne, dateTwo) {  //return true if dateOne is before or equal to dateTwo
  if(!dateOne || !dateTwo) return true;
  let timeOne = createDateByLangStr(dateOne);
  let timeTwo = createDateByLangStr(dateTwo);
  return timeOne.getTime() <= timeTwo.getTime(); 
}

const allSchengenCountryCodes = ['AUT', 'BEL', 'CZE', 'DNK', 'EST', 'FIN', 'FRA', 'DEU', 'GRC', 'HUN', 'ISL'  , 'ITA', 'LVA', 'LIE', 'LTU', 'LUX', 'MLT', 'NLD', 'NOR', 'POL', 'PRT', 'SVK', 'SVN', 'ESP', 'SWE', 'CHE'];

function isSchengenCountry(country) {
  if (!country) return false;
  const schengenCountries = [...allSchengenCountryCodes];
  return schengenCountries.indexOf(country) != -1;
}

function isMinPrimaryInsured(numb) {
  if(!this?.form) return false;
  var flag = false;
  var numOfTraveler = 0;
  var travelerInfos = this.form?.travelerInfos || this.form?.step5.groupTravelerInfos;
  var tLength = travelerInfos.length;

  for(var i = 0 ; i < tLength ; i++){
    if(!isNaN(travelerInfos[i].numOfTraveler)) {
      numOfTraveler += parseInt(travelerInfos[i].numOfTraveler);        
    }
  }
  
  if(numOfTraveler) (numOfTraveler > 1) ? flag = true : flag = false;    
  
  return flag;
}

function maxTriplength(self){
  let startDate = createDateByLangStr(self.form.step4.departureDate);
  let endDate = createDateByLangStr(self.form.step4.returnDate);
  let departureDate = new Date(startDate);
  let returnDate = new Date(endDate);
  let days = (returnDate.getTime() - departureDate.getTime()) / (1000 * 60 * 60 * 24);
  return days <= 365 ? true : false;
}

function maxTravelMedicalLength(self){
  let startDate = createDateByLangStr(self.form.step4.startDate);
  let endDate = createDateByLangStr(self.form.step4.endDate);
  let departureDate = new Date(startDate);
  let returnDate = new Date(endDate);
  let days = (returnDate.getTime() - departureDate.getTime()) / (1000 * 60 * 60 * 24);
  return days <= 365 ? true : false;
}

function ageGroupRepeat(value, self) {
  if(value === '') return true;
  let res = self.form.step5.groupTravelerInfos.filter(traveler => traveler.ageGroup === value);
  return res.length >= 2 ? false : true;
}

function isMobileOrTablet() {
  return window.innerWidth <= 1024; //The width of iPad Pro is 1024
}

function addLinkPresection(presection) {
  // and url parameters into language links
  if(presection) {
    const enLinks = document.getElementsByClassName('c-Lang-H__link c-Lang-H__link--en');
    for(let i = 0; i < enLinks.length; i++) {
        let url = enLinks[i].href;
        if(!url.includes('presection')) enLinks[i].href = url + '?presection=' + presection;
    }

    const chLinks = document.getElementsByClassName('c-Lang-H__link c-Lang-H__link--ch');
    for(let i = 0; i < chLinks.length; i++) {
        let url = chLinks[i].href;
        if(!url.includes('presection')) chLinks[i].href = url + '?presection=' + presection;
    }
    const chineseLink = document.getElementById('chinese-language-link');
    if(chineseLink && chineseLink.href && !chineseLink.href.includes('presection')) {
      chineseLink.href = chineseLink.href + '?presection=' + presection;
    }
    
    const esLinks = document.getElementsByClassName('c-Lang-H__link c-Lang-H__link--es');
    for(let i = 0; i < esLinks.length; i++) {
        let url = esLinks[i].href;
        if(!url.includes('presection')) esLinks[i].href = url + '?presection=' + presection;
    }
  }
}

function mobileAppLogout() {
  try {
    window.flutter_inappwebview.callHandler('loginPage', 121).then(function(result) {
      // action to take on response.
    });
  } catch (err) {
    console.log('App is not enabled.');
  }
}

function openChat() {
  if (typeof window.Genesys === 'function') {
    Genesys("command", "Messenger.open", {},
        function(o){},  // if resolved
        function(o){    // if rejected
            Genesys("command", "Messenger.close");
        }
    );
  }
}

/* 
  exporting all the functions as an object
*/
export {
    isTruthy,
    allowOnlyNumbers,
    zipCodeValidation,
    zipCodeValidation4Residence,
    onlyNumbers,
    onlyNumbersIgnoreSpace,
    onlyAlphaNumeric,
    onlyAlpha,
    alphaWithHyphens,
    alphaNumericIMG,
    addressStringIMG,
    nameRegexValidationIMG,
    phoneNumberIMG,
    firstAndLastNameCheck,
    IMGRoutingNumber,
    numbersWithSpace,
    removeSpaces,
    spouseAgeValidation,
    getParameterByName,
    dateNotToday,
    monthDate,
    dateNotInPast,
    dateNotInFuture,
    isAfterField,
    isAfterFieldIMGCondition,
    isBeforeDepart,
    isBeforeField,
    numberNotNegative,
    mustBeChecked,
    numberGreaterThanZero,
    validDate,
    createDateByLangStr,
    convertDateByLang,
    convertDateString,
    addOrSubtractDate,
    getDateFromLanguage,
    convertDateToEN,
    createDateByLangApplications,
    convertDatei18n_es,
    resetThumbView,
    resetMobileMenu,
    isMinAge,
    isDateGreaterThanToday,
    validateEmail,
    validatePhoneNumber,
    dateFormat,
    isGenderAdded,
    isAgeAdded,
    isGenderSame,
    isCheckBoxSelected,
    totalDaysBetweenDates,
    getAgeFromBirthday,
    checkCanadaPostalCode,
    checkUSzipCodeFormat,
    atleastOneNumber,
    findReferralKey,
    getDurationForApplications,
    capitalizeString,
    lessThan130Years,
    alphaNumericInsubuyIMG,
    alphaNumericInsubuyIMGPhone,
    nameRegexInsubuyIMG,
    addressStringInsubuyIMG,
    requireChildren,
    numbersWithDashSlash,
    noAlphabets,
    requireChildrenAndOthers,
    isInPlanList,
    getDurationForApplicationsWithOptions,
    alphaNumericInsubuyIMGState,
    phoneNumberiTI,
    validatePhoneNumberApp,
    numbersSpacesAndLetters,
    accountingSettings,
    setTimeOutFun,
    resetExpirationTimer,
    convertFormattedServerTime,
    numbersWithComma,
    setPostalMinLength,
    zipCodeValidationChk,
    copyToClipboard,
    getAddrLength,
    phoneNumSetup,
    phoneNumberSplit,
    getAddressStr,
    getStateDictionary,
    getStateCode,
    isChromeBrowser,
    getValidAddresses,
    getPersons,
    getCategoryName,
    saveApplication,
    isChildByAge,
    isChildByTravelerType,
    myAccountLogout,
    isSameStringValue,
    maxAge,
    sortByAlphabeticalOrder,
    getFormattedPersonName,
    deepCopy,
    isTemporaryDisabledProduct,
    maxNum,
    minNum,
    noSplCharacters,
    getLangIdByLang,
    isAfterDate,
    getDaysDifference,
    shouldCheckInsuredIDPerPerson,
    getRefundDetailsSetup,
    isSameDuration,
    multiLanguageDisabedCompanies,
    checkAgent,
    getIMGTripPlanIds,
    getOldITIPlanIds,
    isDiscontinuePlan,
    getShippingAmount,
    getCalculateTotalPremium,
    isAfterToday,
    convertDateStringByEnDate,
    onlyCancelimmedite,
    isSafari,
    isIOSChrome,
    memberBased,
    dateNotBefore,
    debounce,
    isFirefox,
    websiteUrlValidation,
    atleastTenNumbers,
    dateInsert,
    getCountriesFromStorage,
    getCurrentTime,
    addMins,
    getTimeInGivenLang,
    convertToCSTTime,
    matchTimezone,
    convertTimeForCookie,
    isSchengenCountry,
    isMinPrimaryInsured,
    maxTriplength,
    maxTravelMedicalLength,
    ageGroupRepeat,
    isMobileOrTablet,
    alphaNumericIMGPhone,
    onlyAlphaNumericWithApst,
    isDateBeforeOrEqual,
    addLinkPresection,
    allSchengenCountryCodes,
    setStateType,
    mobileAppLogout,
    openChat
}
